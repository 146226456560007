@mixin for-desktop {
    @media only screen and (min-width: 991px) {
        @content;
    }
}

@mixin only-for-mini-desktop {
    @media only screen and (max-width: 1200px) and (min-width: 991px) {
        @content;
    }
}

@mixin for-tablet {
    @media only screen and (max-width: 990px) {
        @content;
    }
}

@mixin only-for-tablet {
    @media only screen and (max-width: 990px) and (min-width: 768px) {
        @content;
    }
}

@mixin for-mobile {
    @media only screen and (max-width: 767px) {
        @content;
    }
}

@mixin new-mobile {
    @media only screen and (max-width: 767px) and (min-width: 412px) {
        @content;
    }
}

@mixin old-ios-mobile {
    @media only screen and (max-width: 411px) and (min-width: 374px) {
        @content;
    }
}