.mastering-page {
    height: calc(100vh - 50px);
    background-image: url("../assets/mastering-bg.jpg");
    background-size: 110% 110%;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;

    &::before {
        content: "";
        position: fixed;
        pointer-events: none;
        width: 30vw;
        height: 30vw;
        top: -120px;
        left: 60%;
        opacity: .075;
        z-index: 1;
        border-radius: 50%;
        background: var(--bs-blue);
        filter: blur(125px);
        transition: all 0.3s ease-in-out;

        @include for-mobile {
            width: 60vw;
            height: 60vw;
            top: 0;
            left: auto;
            right: 0;
            opacity: .33;
        }
    }

    &::after {
        content: "";
        position: fixed;
        pointer-events: none;
        width: 30vw;
        height: 30vw;
        top: 0;
        left: 5%;
        opacity: .075;
        z-index: 0;
        border-radius: 50%;
        background: blueviolet;
        filter: blur(125px);
        transition: all 0.3s ease-in-out;

        @include for-mobile {
            width: 60vw;
            height: 60vw;
            top: 25%;
            left: -5%;
            opacity: .33;
        }
    }

    .mastering-page-row {
        &::after {
            content: "";
            position: fixed;
            pointer-events: none;
            width: 25vw;
            height: 25vw;
            bottom: 0%;
            left: 40%;
            opacity: .05;
            z-index: 0;
            border-radius: 50%;
            background: mediumspringgreen;
            filter: blur(125px);
            transition: all 0.3s ease-in-out;
    
            @include for-mobile {
                width: 60vw;
                height: 60vw;
                bottom: 10%;
                left: auto;
                right: -5%;
                opacity: .33;
            }
        }

        @include for-tablet {
            width: 100vw !important;
            height: 100%;
            position: relative;
            margin: 0;
            overflow: hidden;
        }
    }

    &.mpc-background-0 {
        background-size: 100% 100%;

        &::after, &::before, .mastering-page-row::after {
            opacity: 0;
        }
    }

    &.mpc-background-2 {
        background-size: 115% 115%;
        background-position: bottom;
    }

    @include for-tablet {
        flex-direction: column;
        height: calc(100vh - 210px);
        margin: 0 -0.5rem;
    }
}

.mastering-radios {
    gap: 20px;

    @include for-mobile {
        transform: scale(0.75);
        -webkit-transform: scale(0.75);
    }
}

:root {
    --panel-width: 260px;

    @include for-tablet {
        --panel-width: 420px;
    }
}


.col.mastering-panels {
    width: var(--panel-width);
    max-width: var(--panel-width);
    max-height: calc(100vh - 50px);
    padding: 0;
    position: relative;
    overflow: hidden;
    background: var(--bs-bg-transparent-dark);
    backdrop-filter: var(--bg-blur-light);

    @include for-tablet {
        position: absolute;
        top: 0;
        height: calc(100% - 1px);
        width: 100%;
        z-index: 10;
        border: none;
        background: var(--bs-bg-transparent);
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
    }

    &.mastering-left-panel {
        border-right: 1px solid var(--bs-border-color) !important;
        transition: all 0.1s ease-in-out;
        -webkit-transition: all 0.1s ease-in-out;

        .mastering-left-panel-header {
            padding: 6px 6px 6px 10px;
            font-size: 0.9rem;
            line-height: 24px;

            .mlphc-icon {
                cursor: pointer;
            }

            .icon {
                height: 28px;
                margin-right: 0;
            }

            @include for-tablet {
                display: none;
            }
        }

        &.mastering-left-panel-passive {
            width: 40px;
            max-width: 40px;

            .mastering-footer,
            .accordion,
            .mlphc-text {
                display: none !important;
            }

            .mastering-left-panel-header {
                padding: 0;

                .icon {
                    height: 40px;
                }
            }
        }

        @include for-tablet {
            left: 0;
            transform: translateX(-200%);
            -webkit-transform: translateX(-200%);

            &.mastering-left-panel-mobile-show {
                transform: translateX(0);
                -webkit-transform: translateX(0);
            }
        }
    }

    &.mastering-right-panel {
        border-left: 1px solid var(--bs-border-color) !important;

        @include for-tablet {
            right: 0;
            transform: translateX(200%);
            -webkit-transform: translateX(200%);

            &.mastering-right-panel-mobile-show {
                transform: translateX(0);
                -webkit-transform: translateX(0);
            }
        }
    }

    .mastering-left-panel-content,
    .mastering-right-panel-content {
        height: 100%;
        overflow-y: auto;
        padding-bottom: 140px;

        @include for-tablet {
            padding: 0 0 120px 6px;
        }
    }

    .accordion {
        .accordion-item {
            position: relative;
            border-right: none;
            border-left: none;
        }

        .accordion-header {
            .accordion-button {
                padding: 0.5rem 0.5rem 0.5rem 0.75rem;
                font-size: 0.8rem;
                line-height: 16px;

                &:after {
                    left: auto;
                    right: 0.5rem;
                }

                img.icon {
                    width: 16px;
                    height: 16px;
                    object-fit: contain;
                    margin-right: 10px;
                }
            }
        }

        &.sbscrbdy {
            .accordion-item {
                .suite-badge {
                    display: none;
                }
            }
        }

        &.nsbscrbd {
            .accordion-item {

                .suite-badge {
                    display: none;
                }

                &:nth-child(n+3) {
                    .accordion-body {

                        input,
                        label {
                            pointer-events: none;
                        }
                    }

                    &:before {
                        content: "";
                        position: absolute;
                        position: absolute;
                        top: 32px;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 100%;
                        height: calc(100% - 32px);
                        background: var(--bs-bg-transparent-2);
                        z-index: 10;
                        pointer-events: none;
                    }

                    .suite-badge {
                        display: block;
                        position: absolute;
                        text-align: center;
                        font-size: 0.7rem;
                        line-height: 18px;
                        background: var(--bs-gold) url('data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgZmlsbD0iIzAwMDAwMCIgaGVpZ2h0PSI4MDBweCIgd2lkdGg9IjgwMHB4IiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB2aWV3Qm94PSIwIDAgMzMwIDMzMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnIGlkPSJYTUxJRF81MDlfIj4KCTxwYXRoIGlkPSJYTUxJRF81MTBfIiBkPSJNNjUsMzMwaDIwMGM4LjI4NCwwLDE1LTYuNzE2LDE1LTE1VjE0NWMwLTguMjg0LTYuNzE2LTE1LTE1LTE1aC0xNVY4NWMwLTQ2Ljg2OS0zOC4xMzEtODUtODUtODUgICBTODAsMzguMTMxLDgwLDg1djQ1SDY1Yy04LjI4NCwwLTE1LDYuNzE2LTE1LDE1djE3MEM1MCwzMjMuMjg0LDU2LjcxNiwzMzAsNjUsMzMweiBNMTgwLDIzNC45ODZWMjU1YzAsOC4yODQtNi43MTYsMTUtMTUsMTUgICBzLTE1LTYuNzE2LTE1LTE1di0yMC4wMTRjLTYuMDY4LTQuNTY1LTEwLTExLjgyNC0xMC0xOS45ODZjMC0xMy43ODUsMTEuMjE1LTI1LDI1LTI1czI1LDExLjIxNSwyNSwyNSAgIEMxOTAsMjIzLjE2MiwxODYuMDY4LDIzMC40MjEsMTgwLDIzNC45ODZ6IE0xMTAsODVjMC0zMC4zMjcsMjQuNjczLTU1LDU1LTU1czU1LDI0LjY3Myw1NSw1NXY0NUgxMTBWODV6Ii8+CjwvZz4KPC9zdmc+Cg==') no-repeat;
                        background-size: 24px 14px;
                        background-position: left center;
                        color: var(--bs-black);
                        top: 5px;
                        left: auto;
                        right: 40px;
                        margin: auto;
                        padding: 2px 8px 2px 24px;
                        border-radius: var(--bs-accordion-border-radius);
                        z-index: 11;
                        cursor: not-allowed;
                    }
                }
            }
        }
    }

    .list-group {
        font-size: 0.8rem;

        h6 {
            opacity: 0.8;
            font-size: 0.8rem;
            margin-bottom: 0;
        }

        .cover-image {
            margin: 0 -10px;

            img {
                width: 100%;
                object-fit: cover;

                @include for-tablet {
                    max-height: 32vh;
                }
            }
        }
    }

    .mastering-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: var(--panel-width);
        background-color: var(--bs-black);
        padding: 8px calc(var(--bs-gutter-x) * 0.25) 4px;
        border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
        z-index: 20;

        .revision-footer-button {
            &.bouncee-btn {
                color: var(--bs-btn-hover-color);
                background-color: var(--bs-btn-hover-bg);
                border-color: var(--bs-btn-hover-border-color);
            }
        }

        .mastering-footer-button {
            background: var(--action-gradient);
            border-left-color: var(--action-gradient-border-left-color);
            border-right-color: var(--action-gradient-border-right-color);
        }

        @include for-tablet {
            display: none !important;
        }
    }
}

.col.mastering-page-container {
    position: relative;
    width: calc(100% - (2 * var(--panel-width)));
    background: transparent;
    overflow: hidden;
    background: var(--bs-bg-transparent-dark);

    .cover-fg {
        position: relative;
        z-index: 1;
        max-height: calc(100% - 135px);
        padding-bottom: 10px;
        overflow-x: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 0;
        }

        @include for-tablet {
            max-height: calc(100vh - 200px);

            @media screen and (max-height: 660px) {
                padding-bottom: 100px;
            }
        }
    }

    .prompt-to-master-wrapper {
        --border-radius: 24px;
        --max-width: 820px;
        position: relative;
        margin: 0 -15px;
        padding: 20px;

        .prompt-to-master-container {
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            padding: 2px 4px 2px 8px;
            margin: auto;
            max-width: var(--max-width);
            border-radius: var(--border-radius);
            border: 1px solid var(--bs-secondary-bg);
            background: var(--bs-form-input-background-color);
            backdrop-filter: blur(2px);

            .ptm-random-suggestion {
                position: relative;
                border-radius: 100%;
                padding: 4px 6.5px;
                background: transparent;
                border: 1px solid var(--bs-border-color);

                img {
                    margin: 0;
                    width: 16px;
                    height: 16px;
                }
            }

            .ptm-divider {
                background: linear-gradient(180deg, #84848400, hsla(0, 0%, 52%, .463) 53%, hsla(0, 0%, 52%, .463) 0, #84848400);
                width: 2px;
                height: 30px;
                margin: 0 10px;
            }

            .ptm-input {
                position: relative;
                border: none;
                background: transparent;
                padding: 0 10px 0 2px;
                width: auto;
                flex-grow: 1;
                height: 100%;
                min-height: 38px;
                font-size: 16px;
                white-space: nowrap;
                text-overflow: ellipsis;
                backdrop-filter: none;
            }

            .ptm-apply-btn {
                position: relative;
                padding: 10px 2px;
                line-height: 1;
                z-index: 1;
                border-radius: var(--border-radius);
                overflow: hidden;
                background: transparent;

                @include for-mobile {
                    padding: 3px;
                }

                span {
                    position: relative;
                    padding: 8px 22px;
                    border-radius: 24px;
                    font-weight: normal;
                    color: var(--bs-btn-passive-color);
                    background: var(--bs-body-bg);

                    @include for-mobile {
                        display: none;
                    }
                }

                &:hover {
                    span {
                        color: var(--bs-btn-hover-color);
                    }
                }

                &::before {
                    content: "";
                    position: absolute;
                    background: rgba(0, 230, 20, 0.75);
                    width: 104%;
                    height: 102%;
                    z-index: -1;
                    top: -1%;
                    left: -2%;
                    border-radius: 24px;
                    filter: blur(10px);
                    animation: spin 4s linear infinite;

                    @include for-mobile {
                        background: transparent;
                        box-shadow: 0px 2px 0px 0px rgba(0, 230, 20, 0.75) inset;
                        filter: none;
                    }
                }

                &::after {
                    content: "";
                    position: absolute;
                    background: rgba(0, 230, 20, 0.75);
                    width: 104%;
                    height: 102%;
                    z-index: -1;
                    top: -1%;
                    left: -2%;
                    border-radius: 24px;
                    filter: blur(20px);
                    animation: spin 3s linear infinite;
                    animation-delay: 2s;

                    @include for-mobile {
                        background: transparent;
                        box-shadow: 0px -2px 0px 0px rgba(0, 230, 20, 0.75) inset;
                        filter: none;
                        animation: spin 4s linear infinite;
                        animation-delay: 0s;
                    }

                }

                .m-icon {
                    display: none;

                    @include for-mobile {
                        display: block;
                        background: var(--bs-body-bg);
                        margin: 0 auto;
                        padding: 4px;
                        border-radius: 100%;
                    }
                }
            }
        }
    }

    .assistan-prompted-wrapper {
        --border-radius: 12px;
        --max-width: 820px;
        margin: -10px auto 0;
        padding: 8px;
        max-width: var(--max-width);display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
        text-align: right;
        gap: 15px;
        border-radius: var(--border-radius);
        border: none;
        background: var(--bs-form-input-background-color);

        .assistan-prompted {
            max-width: 90%;
            font-size: 14px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: right;
        }

        .assistant-prompted-icon {
            width: 32px;
            min-width: 32px;
            height: 32px;
            font-size: 14px;
            line-height: 32px;
            margin: -2px 0;
            border-radius: 100%;
            text-align: center;
            text-transform: uppercase;
            background: var(--bs-gradient);

            &.pro-user {
                background: var(--navbar-gradient);
                box-shadow: var(--navbar-boxshadow);
            }
        }
    }

    .assistant-answer-wrapper {
        --border-radius: 12px;
        --max-width: 820px;
        position: relative;
        margin: 0 -15px;
        padding: 10px 20px 15px;

        .assistant-answer-container {
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 15px;
            padding: 0 4px 0 8px;
            margin: 0 auto;
            height: auto;
            max-width: var(--max-width);
            border-radius: var(--border-radius);
            border: none;
            background: var(--bs-form-input-background-color);
            backdrop-filter: blur(2px);
            transition: all 0.3s ease-in-out;
    
            .aa-icon {
                //display: none;
                position: relative;
                border-radius: 100%;
                padding: 3px 6px;
                margin: 8px 0;
                background: transparent;
                border: 1px solid var(--bs-border-color);
    
                img {
                    width: 18px;
                    height: 18px;
                    margin: 0;
                }
            }
    
            .aa-text {
                position: relative;
                font-size: 14px;
                padding: 8px;
                color: var(--bs-white);

                @include for-mobile {
                    font-size: 12px;
                    padding: 6px;

                    @media screen and (max-height: 660px) {
                        font-size: 11px;
                    }
                }
            }
    
            .aa-glow {
                position: absolute;
                display: inline-block;
                margin-left: -70px;
                margin-top: 8px;
                width: 80px;
                height: 10px;
                border-radius: 100%;
                background-color: var(--bs-white);
                opacity: 0.5;
                filter: blur(10px);
                transition: all 0.3s ease-in-out;
            }
            
            .aa-g-hide {
                opacity: 0;
                visibility: hidden;
            }

            @include for-mobile {
                gap: 5px;
            }
        }

        @include for-mobile {
            margin-top: 10px;
        }
    }

    .revision-title {
        color: var(--bs-white);
        .version-title {
            max-width: 90%;
            margin: 15px auto 30px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;

            @include for-mobile {
                margin: 0 auto 15px;
            }
        }
    }

    .revision-soundwave {
        margin-bottom: 20px;
    }


    .track-player-container-col {
        background-image: url("../../public/icons/wave-cropped.png");
        background-repeat: no-repeat;
        background-position: 0px -14px;
        background-size: 100% 140px;
        background-origin: padding-box;
        max-width: 1440px;
        margin: auto;

        @include for-mobile {
            background-image: url("../../public/icons/mobile-wave-cropped.png");
            background-size: 100% 80px;
            background-position: 0px -6px;
        }
    }

    .track-player-container {
        position: relative;
        margin: 0px 39%;

        .track-player {
            opacity: 0;
            pointer-events: none;

            .sound-play-pause {
                margin-top: -10px;
                img.icon {
                    margin-right: 0;
                }
            }

            &.active {
                opacity: 1;
                pointer-events: initial;
            }

            &.original-track {
                position: relative;
            }

            &:not(.original-track) {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
            }
        }

        @include for-mobile {
            margin: 0px 20%;
        }
    }

    .cover-footer {
        --cf-padding: 10px;
        position: absolute;
        top: auto;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        padding: 0 calc(var(--cf-padding) / 2);
        font-size: 0.8rem;
        color: var(--bs-light);
        background: var(--bs-bg-transparent-5);
        backdrop-filter: blur(4px);
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;

        &.cf-footer-passive {
            cursor: not-allowed;
            opacity: 0.1;
            bottom: -300px;

            .cf-item {
                cursor: not-allowed;
                pointer-events: none;
            }
        }

        .cf-header {
            padding: var(--cf-padding);
            font-weight: bold;

            img.icon {
                display: block;
                margin: 0;
                height: 18px;
            }

            @include for-tablet {
                display: none;
            }
        }

        .cf-item {
            background: var(--bs-bg-transparent);
            opacity: 0.6;
            color: var(--bs-secondary);
            padding: var(--cf-padding);
            border-radius: var(--bs-border-radius);
            cursor: pointer;

            &:nth-child(odd) {
                background: var(--bs-bg-transparent);
            }

            &.cf-item-active {
                opacity: 1;
                font-weight: bold;
                color: var(--bs-white);
                background: var(--bs-gray-900);

                @include for-tablet {
                    background: var(--bs-dark);
                }
            }

            &:hover {
                color: var(--bs-white);
                background: var(--bs-gray-900);
            }

            .cf-track-name {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;

                @include for-tablet {
                    width: auto !important;
                    max-width: 120px !important;
                }
            }

            .w-10 {
                @include for-tablet {
                    display: none;
                }
            }

            div {
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
                padding-right: 3px;
            }

            @include for-tablet {
                padding: 5px 15px;
                margin-right: 5px;
                border-radius: 5px;
                font-weight: bold;
                background: var(--bs-bg-transparent);
            }
        }

        .cf-container {
            max-height: calc(100vh - 640px);
            padding-bottom: 8px;
            overflow-x: hidden;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: space-between;
            align-items: stretch;
            border-top: 1px solid var(--bs-border-color);
            border-bottom: 1px solid var(--bs-border-color);

            @include for-desktop {
                @media screen and (max-height: 740px) and (min-height: 681px) {
                    max-height: calc(100vh - 600px);
                }
                @media screen and (max-height: 680px) {
                    max-height: 80px;
                }
            }


            @include for-tablet {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: 5px 5px 8px;
                max-height: auto;
                overflow-x: auto;
                overflow-y: hidden;

                &::-webkit-scrollbar {
                    height: 0;
                }

                @media screen and (max-height: 740px) {
                    max-height: none;
                }
            }
        }

        @include for-tablet {
            position: fixed;
            bottom: 110px;
            z-index: 100;
            padding: 0 calc(var(--cf-padding) / 2) calc(var(--cf-padding) / 2);
        }
    }
}

.fixed-msatering-btn {
    position: fixed;
    z-index: 100;
    top: 2px;
    right: 90px;

    .fmb-self {
        padding: 10px;
        cursor: pointer;

        img.icon {
            filter: brightness(5) drop-shadow(0px 0px 6px var(--bs-yellow));
            -webkit-filter: brightness(5) drop-shadow(0px 0px 6px var(--bs-yellow));
        }

        &:hover {
            color: white;

            img.icon {
                animation: spin 10s linear infinite;
                -webkit-animation: spin 10s linear infinite;
            }
        }
    }
}

/* spin animation */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

button.colored-btn.ap-btn.btn {
    padding: 12px;
    font-size: 16px;
}

.ap-suggestions {
    background: var(--bs-form-input-background-color);
    padding: 10px 16px;
    gap: 6px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;

    .ap-suggestion-text {
        margin-right: 8px;
    }

    .ap-suggestion-item {
        padding: 8px 14px;
        background: var(--bs-border-color);
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            background: var(--bs-body-bg);
        }

        .icon {
            height: 18px;
            margin-right: 8px;
            margin-top: -4px;
        }
    }
}

/* mastering wave loading */
.wsp-not-ready {

    .sound-wave,
    .sound-play-pause,
    .sound-text {
        opacity: 0;
    }
}

.wsp-ready {

    .sound-wave,
    .sound-play-pause,
    .sound-text {
        opacity: 1;
    }
}

.wsp-loading-container {
    position: absolute;
    top: 7px;
    left: 0;
    right: 0;
    width: 100%;
    height: 100px;

    @include for-mobile {
        top: 0;
        height: 70px;
    }
}

.upload-wsp-loading {
    .wsp-loading-container {
        max-width: 240px;
        margin: auto;

        @include for-mobile {
            top: 30px;
        }
    }
}

.revision-list {
    .wsp-loading-container {
        height: 100%;
        top: 0;

        .wsp-sound-icon {
            .wsp-sound-wave {
                position: relative;

                .wsp-bar {
                    width: .5%;
                }
            }
        }
    }
}

.wsp-sound-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.wsp-sound-wave {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .wsp-bar {
        display: block;
        width: 2%;
        height: 100%;
        background: var(--bs-border-color);
        border-radius: 10px;
        animation: wspSound 0ms -800ms linear infinite alternate;
        transition: height 1.5s;
        -webkit-animation: wspSound 0ms -800ms linear infinite alternate;
        -webkit-transition: height 1.5s;
    }
}

@keyframes wspSound {
    0% {
        opacity: 0.35;
        height: 10%;
    }

    100% {
        opacity: 1;
        height: 100%;
    }
}

@-webkit-keyframes wspSound {
    0% {
        opacity: 0.35;
        height: 10%;
    }

    100% {
        opacity: 1;
        height: 100%;
    }
}

$bar-count: 64;

@for $i from 1 through $bar-count {
    $random-duration: random(800) + 600;

    .wsp-bar:nth-child(#{$i}) {
        animation-duration: #{$random-duration}ms;
        -webkit-animation-duration: #{$random-duration}ms;
    }
}


/* sound loading text */
.wsp-strow-container {
    position: absolute;
    top: 200px;
    left: 0;
    right: 0;
    margin: auto;

    @include for-mobile {
        top: 100px;
    }
}

div.loadingAnimation {
    position: relative;
    left: 3px;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    letter-spacing: 5px;

    &.la-mini {
        font-size: 60%;
        letter-spacing: 2px;
    }
}

.wsp-blink {
    animation-name: blinker;
    animation-duration: 1.75s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-name: blinker;
    -webkit-animation-duration: 1.75s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes blinker {
    0% {
        opacity: 1.0;
    }

    50% {
        opacity: 0.0;
    }

    100% {
        opacity: 1.0;
    }
}

@keyframes blinker {
    0% {
        opacity: 1.0;
    }

    50% {
        opacity: 0.0;
    }

    100% {
        opacity: 1.0;
    }
}

@for $i from 1 through 20 {
    .wsp-blink:nth-child(#{$i}) {
        -webkit-animation-delay: #{$i*100}ms;
        animation-delay: #{$i*100}ms;
    }
}


.mastering-page-mobile-navigator {
    display: none;

    @include for-tablet {
        display: block;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        --bs-bg-opacity: 1;
        background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity));
        border-top: 1px solid var(--bs-secondary-border-subtle);

        .mpmn-top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .mpmnt-content {
                width: 33.3%;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                margin: 0.25rem;
                padding: 0.5rem;
                border-radius: 8px;
                background: var(--bs-dark);

                &.mpmnt-active {
                    background: transparent;
                }

                img {
                    height: 16px;
                }

                span {
                    max-width: 60px;
                    white-space: wrap;
                    text-align: center;
                    margin: auto;
                    font-size: 12px;
                    line-height: 1;
                }
            }
        }

        .mpmn-bottom {
            position: relative;
            width: 100%;
            height: 50px;
            padding: 0.25rem;

            .mastering-mobile-button {
                position: absolute;
                top: 0;
                left: 0.25rem;
                right: 0.25rem;
                bottom: 0.25rem;
                width: auto !important;
                height: auto !important;
                transition: all 0.3s ease-in-out;
                -webkit-transition: all 0.3s ease-in-out;

                &.mmb-revision {
                    transform: translateY(200%);
                    -webkit-transform: translateY(200%);

                    &.mmb-active {
                        transform: translateX(0);
                        -webkit-transform: translateX(0);
                    }

                    &.bouncee-btn {
                        color: var(--bs-btn-hover-color);
                        background-color: var(--bs-btn-hover-bg);
                        border-color: var(--bs-btn-hover-border-color);
                    }
                }

                &.mmb-master-now {
                    background: var(--action-gradient);
                    border-left-color: var(--action-gradient-border-left-color);
                    border-right-color: var(--action-gradient-border-right-color);
                    transform: translateX(0);
                    -webkit-transform: translateX(0);

                    &.mmb-passive {
                        transform: translateY(200%);
                        -webkit-transform: translateY(200%);
                    }
                }
            }
        }
    }
}

@include for-tablet {
    .mastering-page-row {

        .col.mastering-panels {

            &.mastering-left-panel,
            &.mastering-right-panel {
                

                .mastering-left-panel-content,
                .mastering-right-panel-content {
                    

                    .sbscrbdy.accordion {
                        .accordion-item:first-child {
                            border-top: none;
                        }

                        .accordion-item {
                            .accordion-body {
                                .btn:hover {
                                    border-color: transparent !important;
                                }
                            }
                        }
                    }

                    .btn-check + label.btn {
                        background: var(--bs-black-light);
                    }

                    .btn-check:checked + .btn, .selected-toggle, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active {
                        background: var(--bs-dark);
                    }
                }
            }
        }

    }
}

@include for-mobile {

    div::part(region),
    div[part=region] {
        height: 87% !important;
    }

    @media screen and (max-height: 620px) {

        .sound-text {
            font-size: 0.6rem;
        }
    }
}