img.icon {
    object-fit: contain;
    height: 24px;
    margin-right: 10px;
    &.big-icon {
        height: 48px;
    }
    &.notion-icon {
        height: 150px;

        @include for-mobile {
            height: 100px;
        }
    }
}

img.icon.icon-18 {
    height: 18px;
}

img.icon.icon-36 {
    height: 36px;
}

img.renew-disabled {
    pointer-events: none;
    opacity: 0.1;
}


.icon-link {
    display: inline-flex;
    gap: .375rem;
    align-items: center;
    -webkit-text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
    text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
    text-underline-offset: 0.25em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.icon-link>.bi {
    flex-shrink: 0;
    width: 1em;
    height: 1em;
    fill: currentcolor;
    transition: .2s ease-in-out transform
}

@media (prefers-reduced-motion:reduce) {
    .icon-link>.bi {
        transition: none
    }
}

.icon-link-hover:focus-visible>.bi,
.icon-link-hover:hover>.bi {
    transform: var(--bs-icon-link-transform, translate3d(.25em, 0, 0))
}

/* infinite rotate */
img.icon.preloader {
    animation: spin 3s linear infinite;
    -webkit-animation: spin 3s linear infinite;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

.icon.icon-wave {
    height: auto;
}

.icon.icon-mastering {
    height: 50px;
    width: 50px;
    margin: auto !important;
}
.icon.user-account-icon {
    height: 64px;
    width: 64px;
    object-fit: contain;
    margin: auto;
}
.icon.payment-success-icon {
    height: 100px;
    width: 100px;
    object-fit: contain;
    margin: auto;
    border-radius: 100%;
    padding: 12px;
    border: 4px solid var(--bs-success);
}
.icon.payment-error-icon {
    height: 100px;
    width: 100px;
    object-fit: contain;
    margin: auto;
    border-radius: 100%;
    padding: 26px;
    border: 4px solid var(--bs-danger-border-subtle);
}
.icon.payment-warning-icon {
    height: 100px;
    width: 100px;
    object-fit: contain;
    margin: auto;
    padding: 0;
}

.icon.icon-swipe {
    height: 64px;
    width: 64px;
    /* hand swipe animation with rotate 45 deg right and left origin to bottom center shake */
    animation: swipe 3s ease-in-out infinite, fade 10s ease-in-out infinite;
    -webkit-animation: swipe 3s ease-in-out infinite, fade 10s ease-in-out infinite;
    transform-origin: bottom center;
    -webkit-transform-origin: bottom center;
}

/* swipe animation */
@keyframes swipe {
    0% { transform: rotate(40deg); }
    50% { transform: rotate(-40deg); }
    100% { transform: rotate(40deg); }
}
@-webkit-keyframes swipe {
    0% { -webkit-transform: rotate(40deg); }
    50% { -webkit-transform: rotate(-40deg); }
    100% { -webkit-transform: rotate(40deg); }
}

/* fade animation */
@keyframes fade {
    0% { opacity: 0; }
    30% { opacity: .0; }
    50% { opacity: .7; }
    70% { opacity: .0; }
    100% { opacity: 0; }
}
@-webkit-keyframes fade {
    0% { opacity: 0; }
    30% { opacity: .0; }
    50% { opacity: .7; }
    70% { opacity: .0; }
    100% { opacity: 0; }
}

.dropdown-toggle.dots-btn {
    background: transparent;
    border: none;
    text-align: center;
    padding: 0 5px;
}

.dropdown-toggle.dots-btn:after {
    display: none;
}

.dropdown-toggle.dots-btn img {
    margin: 0;
}