.accountPage {
    .account-card-body {
        padding: 5vh 2vw;
        .acc-desc {
            max-width: 580px;
            width: 90vw;
            margin: auto;
        }
    }
    .account-action-buttons {
        max-width: 480px;
        margin: auto;
    }
}

.account-stats {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-bottom: 15px;

    @include for-mobile {
        gap: 20px;
    }

    .as-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 110px;

        @include for-mobile {
            width: 90px;
        }

        .as-item-icon {
            margin-right: 10px;

            @include for-mobile {
                margin-right: 5px;
            }

            img {
                margin: auto;
                width: auto;
                height: 40px;

                @include for-mobile {
                    height: 30px;
                }

                &.ac-mastering {
                    height: 55px;
                    margin-top: -10px;

                    @include for-mobile {
                        height: 45px;
                        margin-top: -5px;
                    }
                }
            }
        }

        .as-item-text {
            display: flex;
            flex-direction: column;
            color: var(--bs-white);
            line-height: 1;
            
            .ait-number {
                font-size: 29px;

                @include for-mobile {
                    font-size: 22px;
                }
            }

            .ait-name {
                font-size: 11px;

                @include for-mobile {
                    font-size: 10px;
                }
            }
        }
    }
}

.referring-wrapper {
    margin: 10px auto 0;
    width: 400px;
    max-width: 95%;

    .referring-infos {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin: 5px auto 10px;
        color: var(--bs-white);

        .referring-iitem {
            width: 50%;
            text-align: center;
            padding: 5px;
            border: var(--bs-border-width) solid var(--bs-border-color);
            backdrop-filter: var(--bg-blur);
            border-radius: var(--bs-border-radius);

            .referring-text {
                font-size: 12px;
            }

            .referring-number {
                font-size: 16px;
            }
        }
    }

    .copy-btn {
        white-space: nowrap;
        overflow: hidden;
        position: relative;
        background: var(--bs-black-light);
        padding-left: 20px !important;

        .copy-icon {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            height: 100%;
            margin: 0 auto;
            padding: 6px 9px;
            background: var(--bs-black-light);
            transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
        }

        &:active .copy-icon {
            background: var(--bs-gray-900);
        }
    }
}

.delete-account-button {
    position: fixed;
    right: 20px;
    bottom: 20px;

    @include for-mobile {
        position: relative;
        right: auto;
        bottom: auto;
        text-align: center;
        margin-bottom: 20px;
    }
}