.price-cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;

    .price-card {
        position: relative;
        padding: 30px 15px 20px;
        margin: 0 0 20px;
        min-width: 175px;
        width: 33%;
        background: var(--bs-bg-transparent-2);
        border-radius: var(--bs-more-border-radius);
        border: 2px solid var(--bs-border-color);
        cursor: pointer;

        .selected-tag, .price-card-bottom {
            position: absolute;
            top: -12px;
            left: 50%;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            padding: 1px 20px;
            font-size: 12px;
            font-weight: bold;
            white-space: nowrap;
            background: var(--bs-bg-transparent);
            border-radius: var(--bs-border-radius);
            border: 1px solid var(--bs-border-color);
        }

        .price-card-content {

            .price-card-header {
                font-size: 14px;
            }

            .price-card-price {
                font-size: 24px;
                font-weight: bold;
                color: white;
                margin: 0px 0 5px;

                span {
                    font-size: 12px;
                    font-weight: normal;
                    white-space: nowrap;
                    color: var(--bs-secondary-text-emphasis);
                }
            }

            .price-card-features {
                padding-left: 15px;

                li {
                    font-size: 12px;
                    height: 24px;
                    line-height: 14px;
                }
            }
        }

        .price-card-bottom {
            top: auto;
            bottom: -10px;
            left: 50%;

            &.pcb-btn {
                padding: 1px 1px;

                button {
                    padding: 4px 10px;
                    background: transparent !important;
                    border: none !important;
                    outline: none !important;
                    color: white;
                    font-size: 14px;
                    line-height: 1;
                    font-weight: bold;
                }
            }

            .quantity-selector {
                display: flex;
                width: 100%;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 5px;
            
            }
        }

        &.selected {
            border: 2px solid var(--bs-green);
            .selected-tag, .price-card-bottom {
                background: var(--bs-green);
                border: 2px solid var(--bs-green);
                color: white;
            }
        }
    }
}

@include for-mobile {
    .mobile-paywall-title {
        font-size: 22px;
    }
    .price-cards-container {
        flex-wrap: wrap;
        padding: 0 !important;
        .price-card {
            width: calc(50% - 5px);
            min-width: auto;
            .price-card-content {
                .price-card-header {
                    font-size: 12px;
                }
                .price-card-price {
                    font-size: 20px;
                }
                .price-card-features li {
                    font-size: 11px;
                    height: 16px;
                    line-height: 12px;
                }
            }
        }
    }
}