$font-mono: monospace;
$ease-cb: cubic-bezier(.19, 1, .22, 1);

@mixin nth-ani-delay($delay_items: 7, $delay_time: 0.2s) {
    @for $i from 1 through $delay_items {
        &:nth-child(#{$i}) {
            animation-delay: $delay_time * $i;
        }
    }
}

@mixin bg-overlay($top_layer: 'header') {
    &:after {
        z-index: 0;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    &>#{$top_layer} {
        z-index: 2;
        position: relative;
    }
}

.main-quotes {
    margin: 0;
    width: 100%;
    font-family: $font-mono;
    line-height: 1.5;
    font-weight: 400;
    font-style: normal;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(100% - 50px);
    background: transparent;
}

.sep {
    border: 0;
    width: 3em;
    height: 1px;
    margin: 1em 0;
    background-color: rgba(255, 255, 255, 0.4);
}

.mast {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    color: #fff;
    background-color: transparent;
    @include bg-overlay('header');

    @include for-tablet {
        align-items: flex-start;
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        margin: 0;
        padding: 0;
        background-size: cover;
        background-position: 50%;
    }

    &__header {
        padding: 3% 4%;
        max-width: 640px;

        @include for-tablet {
            padding: 6vh 3vw 1vh;

            @media screen and (max-height: 620px) {
                padding: 4vh 3vw 1vh;
            }
        }
    }

    &__title {
        font-family: $font-mono;
        font-size: 1em;
        font-weight: 400;
        letter-spacing: 0.3em;
        text-transform: uppercase;
    }

    // Letter animation
    &__title span {
        animation: letter-glow 0.7s 0s ease both;
        @include nth-ani-delay(500, 0.05s);
    }

    //Letter animation
    &__text {
        font-size: 1em;
        margin: 0;
        line-height: 1.5;

        @include for-mobile {
            font-size: 0.8em;
        }
    }

    &__text span {
        animation: letter-glow 0.7s 0s ease both;
        @include nth-ani-delay(500, 0.05s);
    }
}


@keyframes letter-glow {
    0% {
        opacity: 0;
        text-shadow: 0px 0px 1px rgba(255, 255, 255, 0.1)
    }

    66% {
        opacity: 1;
        text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.9)
    }

    77% {
        opacity: 1;
    }

    100% {
        opacity: 0.7;
        text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.0)
    }
}