.fullPageLoading {
    position: fixed;
    top: 50px;
    left: 0;
    z-index: 9999;
    width: 100vw;
    height: calc(100vh - 50px);
    background: var(--bs-body-bg);
    &.fullPageLoadingBlur {
        background: var(--bs-bg-transparent);
        backdrop-filter: var(--bg-blur-light);
    }
}
.fullPageWarning {
    top: 50px !important;
}
.relativeLoading {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9998;
    width: 100%;
    height: 100%;
}
.toast-fullPage {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 10000;
    width: 100vw;
    height: 100%;
    background: transparent;
    pointer-events: none;
}
.ratio {
    position: relative;
    width: 100%
}

.ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""
}

.ratio>* {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.ratio-1x1 {
    --bs-aspect-ratio: 100%
}

.ratio-4x3 {
    --bs-aspect-ratio: 75%
}

.ratio-16x9 {
    --bs-aspect-ratio: 56.25%
}

.ratio-21x9 {
    --bs-aspect-ratio: 42.8571428571%
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030
}

.sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020
}

.sticky-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020
}

@media (min-width:576px) {
    .sticky-sm-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-sm-bottom {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width:768px) {
    .sticky-md-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-md-bottom {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width:992px) {
    .sticky-lg-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-lg-bottom {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width:1200px) {
    .sticky-xl-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-xl-bottom {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width:1400px) {
    .sticky-xxl-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-xxl-bottom {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

.hstack {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch
}

.vstack {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-self: stretch
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important
}

.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption),
.visually-hidden:not(caption) {
    position: absolute !important
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: ""
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.vr {
    display: inline-block;
    align-self: stretch;
    width: 1px;
    min-height: 1em;
    background-color: currentcolor;
    opacity: .25
}

.align-baseline {
    vertical-align: baseline !important
}

.align-top {
    vertical-align: top !important
}

.align-middle {
    vertical-align: middle !important
}

.align-bottom {
    vertical-align: bottom !important
}

.align-text-bottom {
    vertical-align: text-bottom !important
}

.align-text-top {
    vertical-align: text-top !important
}

.float-start {
    float: left !important
}

.float-end {
    float: right !important
}

.float-none {
    float: none !important
}

.object-fit-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important
}

.object-fit-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important
}

.object-fit-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important
}

.object-fit-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important
}

.object-fit-none {
    -o-object-fit: none !important;
    object-fit: none !important
}

.opacity-0 {
    opacity: 0 !important
}

.opacity-25 {
    opacity: .25 !important
}

.opacity-50 {
    opacity: .5 !important
}

.opacity-75 {
    opacity: .75 !important
}

.opacity-100 {
    opacity: 1 !important
}

.overflow-auto {
    overflow: auto !important
}

.overflow-hidden {
    overflow: hidden !important
}

.overflow-visible {
    overflow: visible !important
}

.overflow-scroll {
    overflow: scroll !important
}

.overflow-x-auto {
    overflow-x: auto !important
}

.overflow-x-hidden {
    overflow-x: hidden !important
}

.overflow-x-visible {
    overflow-x: visible !important
}

.overflow-x-scroll {
    overflow-x: scroll !important
}

.overflow-y-auto {
    overflow-y: auto !important
}

.overflow-y-hidden {
    overflow-y: hidden !important
}

.overflow-y-visible {
    overflow-y: visible !important
}

.overflow-y-scroll {
    overflow-y: scroll !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-grid {
    display: grid !important
}

.d-inline-grid {
    display: inline-grid !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: flex !important
}

.d-inline-flex {
    display: inline-flex !important
}

.d-none {
    display: none !important
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important
}

.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important
}

.shadow-none {
    box-shadow: none !important
}

.focus-ring-primary {
    --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity))
}

.focus-ring-secondary {
    --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity))
}

.focus-ring-success {
    --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity))
}

.focus-ring-info {
    --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity))
}

.focus-ring-warning {
    --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity))
}

.focus-ring-danger {
    --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity))
}

.focus-ring-light {
    --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity))
}

.focus-ring-dark {
    --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity))
}

.position-static {
    position: static !important
}

.position-relative {
    position: relative !important
}

.position-absolute {
    position: absolute !important
}

.position-fixed {
    position: fixed !important
}

.position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important
}



.top-0 {
    top: 0 !important
}

.top-50 {
    top: 50% !important
}

.top-100 {
    top: 100% !important
}

.bottom-0 {
    bottom: 0 !important
}

.bottom-50 {
    bottom: 50% !important
}

.bottom-100 {
    bottom: 100% !important
}

.start-0 {
    left: 0 !important
}

.start-50 {
    left: 50% !important
}

.start-100 {
    left: 100% !important
}

.end-0 {
    right: 0 !important
}

.end-50 {
    right: 50% !important
}

.end-100 {
    right: 100% !important
}

.translate-middle {
    transform: translate(-50%, -50%) !important
}

.translate-middle-x {
    transform: translateX(-50%) !important
}

.translate-middle-y {
    transform: translateY(-50%) !important
}



.w-10 {
    width: 10% !important
}

.w-20 {
    width: 20% !important
}

.w-25 {
    width: 25% !important
}

.w-30 {
    width: 30% !important
}

.w-40 {
    width: 40% !important
}

.w-50 {
    width: 50% !important
}

.w-60 {
    width: 60% !important
}

.w-70 {
    width: 70% !important
}

.w-75 {
    width: 75% !important
}

.w-80 {
    width: 80% !important
}

.w-90 {
    width: 90% !important
}

.w-100 {
    width: 100% !important
}

.w-auto {
    width: auto !important
}

.mw-100 {
    max-width: 100% !important
}

.vw-100 {
    width: 100vw !important
}

.min-vw-100 {
    min-width: 100vw !important
}

.h-25 {
    height: 25% !important
}

.h-50 {
    height: 50% !important
}

.h-75 {
    height: 75% !important
}

.h-100 {
    height: 100% !important
}

.h-auto {
    height: auto !important
}

.mh-100 {
    max-height: 100% !important
}

.vh-100 {
    height: 100vh !important
}

.min-vh-100 {
    min-height: 100vh !important
}

.flex-fill {
    flex: 1 1 auto !important
}

.flex-row {
    flex-direction: row !important
}

.flex-column {
    flex-direction: column !important
}

.flex-row-reverse {
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    flex-direction: column-reverse !important
}

.flex-grow-0 {
    flex-grow: 0 !important
}

.flex-grow-1 {
    flex-grow: 1 !important
}

.flex-shrink-0 {
    flex-shrink: 0 !important
}

.flex-shrink-1 {
    flex-shrink: 1 !important
}

.flex-wrap {
    flex-wrap: wrap !important
}

.flex-nowrap {
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
}

.justify-content-start {
    justify-content: flex-start !important
}

.justify-content-end {
    justify-content: flex-end !important
}

.justify-content-center {
    justify-content: center !important
}

.justify-content-between {
    justify-content: space-between !important
}

.justify-content-around {
    justify-content: space-around !important
}

.justify-content-evenly {
    justify-content: space-evenly !important
}

.align-items-start {
    align-items: flex-start !important
}

.align-items-end {
    align-items: flex-end !important
}

.align-items-center {
    align-items: center !important
}

.align-items-baseline {
    align-items: baseline !important
}

.align-items-stretch {
    align-items: stretch !important
}

.align-content-start {
    align-content: flex-start !important
}

.align-content-end {
    align-content: flex-end !important
}

.align-content-center {
    align-content: center !important
}

.align-content-between {
    align-content: space-between !important
}

.align-content-around {
    align-content: space-around !important
}

.align-content-stretch {
    align-content: stretch !important
}

.align-self-auto {
    align-self: auto !important
}

.align-self-start {
    align-self: flex-start !important
}

.align-self-end {
    align-self: flex-end !important
}

.align-self-center {
    align-self: center !important
}

.align-self-baseline {
    align-self: baseline !important
}

.align-self-stretch {
    align-self: stretch !important
}

.order-first {
    order: -1 !important
}

.order-0 {
    order: 0 !important
}

.order-1 {
    order: 1 !important
}

.order-2 {
    order: 2 !important
}

.order-3 {
    order: 3 !important
}

.order-4 {
    order: 4 !important
}

.order-5 {
    order: 5 !important
}

.order-last {
    order: 6 !important
}

.m-0 {
    margin: 0 !important
}

.m-1 {
    margin: .25rem !important
}

.m-2 {
    margin: .5rem !important
}

.m-3 {
    margin: 1rem !important
}

.m-4 {
    margin: 1.5rem !important
}

.m-5 {
    margin: 3rem !important
}

.m-auto {
    margin: auto !important
}

.mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
}

.mx-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important
}

.mx-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important
}

.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important
}

.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
}

.mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important
}

.my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
}

.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
}

.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
}

.my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
}

.mt-0 {
    margin-top: 0 !important
}

.mt-1 {
    margin-top: .25rem !important
}

.mt-2 {
    margin-top: .5rem !important
}

.mt-3 {
    margin-top: 1rem !important
}

.mt-4 {
    margin-top: 1.5rem !important
}

.mt-5 {
    margin-top: 3rem !important
}

.mt-auto {
    margin-top: auto !important
}

.me-0 {
    margin-right: 0 !important
}

.me-1 {
    margin-right: .25rem !important
}

.me-2 {
    margin-right: .5rem !important
}

.me-3 {
    margin-right: 1rem !important
}

.me-4 {
    margin-right: 1.5rem !important
}

.me-5 {
    margin-right: 3rem !important
}

.me-auto {
    margin-right: auto !important
}

.mb-0 {
    margin-bottom: 0 !important
}

.mb-1 {
    margin-bottom: .25rem !important
}

.mb-2 {
    margin-bottom: .5rem !important
}

.mb-3 {
    margin-bottom: 1rem !important
}

.mb-4 {
    margin-bottom: 1.5rem !important
}

.mb-5 {
    margin-bottom: 3rem !important
}

.mb-auto {
    margin-bottom: auto !important
}

.ms-0 {
    margin-left: 0 !important
}

.ms-1 {
    margin-left: .25rem !important
}

.ms-2 {
    margin-left: .5rem !important
}

.ms-3 {
    margin-left: 1rem !important
}

.ms-4 {
    margin-left: 1.5rem !important
}

.ms-5 {
    margin-left: 3rem !important
}

.ms-auto {
    margin-left: auto !important
}

.p-0 {
    padding: 0 !important
}

.p-1 {
    padding: .25rem !important
}

.p-2 {
    padding: .5rem !important
}

.p-3 {
    padding: 1rem !important
}

.p-4 {
    padding: 1.5rem !important
}

.p-5 {
    padding: 3rem !important
}

.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
}

.px-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important
}

.px-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
}

.px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important
}

.py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
}

.pt-0 {
    padding-top: 0 !important
}

.pt-1 {
    padding-top: .25rem !important
}

.pt-2 {
    padding-top: .5rem !important
}

.pt-3 {
    padding-top: 1rem !important
}

.pt-4 {
    padding-top: 1.5rem !important
}

.pt-5 {
    padding-top: 3rem !important
}

.pe-0 {
    padding-right: 0 !important
}

.pe-1 {
    padding-right: .25rem !important
}

.pe-2 {
    padding-right: .5rem !important
}

.pe-3 {
    padding-right: 1rem !important
}

.pe-4 {
    padding-right: 1.5rem !important
}

.pe-5 {
    padding-right: 3rem !important
}

.pb-0 {
    padding-bottom: 0 !important
}

.pb-1 {
    padding-bottom: .25rem !important
}

.pb-2 {
    padding-bottom: .5rem !important
}

.pb-3 {
    padding-bottom: 1rem !important
}

.pb-4 {
    padding-bottom: 1.5rem !important
}

.pb-5 {
    padding-bottom: 3rem !important
}

.ps-0 {
    padding-left: 0 !important
}

.ps-1 {
    padding-left: .25rem !important
}

.ps-2 {
    padding-left: .5rem !important
}

.ps-3 {
    padding-left: 1rem !important
}

.ps-4 {
    padding-left: 1.5rem !important
}

.ps-5 {
    padding-left: 3rem !important
}

.gap-0 {
    gap: 0 !important
}

.gap-1 {
    gap: .25rem !important
}

.gap-2 {
    gap: .5rem !important
}

.gap-3 {
    gap: 1rem !important
}

.gap-4 {
    gap: 1.5rem !important
}

.gap-5 {
    gap: 3rem !important
}

.row-gap-0 {
    row-gap: 0 !important
}

.row-gap-1 {
    row-gap: .25rem !important
}

.row-gap-2 {
    row-gap: .5rem !important
}

.row-gap-3 {
    row-gap: 1rem !important
}

.row-gap-4 {
    row-gap: 1.5rem !important
}

.row-gap-5 {
    row-gap: 3rem !important
}

.column-gap-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important
}

.column-gap-1 {
    -moz-column-gap: 0.25rem !important;
    column-gap: .25rem !important
}

.column-gap-2 {
    -moz-column-gap: 0.5rem !important;
    column-gap: .5rem !important
}

.column-gap-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important
}

.column-gap-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important
}

.column-gap-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important
}

.font-monospace {
    font-family: var(--bs-font-monospace) !important
}

.fs-1 {
    font-size: calc(1.375rem + 1.5vw) !important
}

.fs-2 {
    font-size: calc(1.325rem + .9vw) !important
}

.fs-3 {
    font-size: calc(1.3rem + .6vw) !important
}

.fs-4 {
    font-size: calc(1.275rem + .3vw) !important
}

.fs-5 {
    font-size: 1.25rem !important
}

.fs-6 {
    font-size: 1rem !important
}

.fs-7 {
    font-size: .9rem !important
}

.fs-8 {
    font-size: .8rem !important
}

.fst-italic {
    font-style: italic !important
}

.fst-normal {
    font-style: normal !important
}

.fw-lighter {
    font-weight: lighter !important
}

.fw-light {
    font-weight: 300 !important
}

.fw-normal {
    font-weight: 400 !important
}

.fw-medium {
    font-weight: 500 !important
}

.fw-semibold {
    font-weight: 600 !important
}

.fw-bold {
    font-weight: 700 !important
}

.fw-bolder {
    font-weight: bolder !important
}

.lh-1 {
    line-height: 1 !important
}

.lh-sm {
    line-height: 1.25 !important
}

.lh-base {
    line-height: 1.5 !important
}

.lh-lg {
    line-height: 2 !important
}

.text-start {
    text-align: left !important
}

.text-end {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

.text-right {
    text-align: right !important
}

.text-left {
    text-align: left !important
}

.bg-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important
}

.bg-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important
}

.bg-success {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important
}

.bg-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important
}

.bg-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important
}

.bg-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important
}

.bg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important
}

.bg-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important
}

.bg-black {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important
}

.bg-white {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important
}

.bg-body {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important
}

.bg-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important
}

.bg-body-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important
}

.bg-body-tertiary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important
}

.bg-opacity-10 {
    --bs-bg-opacity: 0.1
}

.bg-opacity-25 {
    --bs-bg-opacity: 0.25
}

.bg-opacity-50 {
    --bs-bg-opacity: 0.5
}

.bg-opacity-75 {
    --bs-bg-opacity: 0.75
}

.bg-opacity-100 {
    --bs-bg-opacity: 1
}

.bg-primary-subtle {
    background-color: var(--bs-primary-bg-subtle) !important
}

.bg-secondary-subtle {
    background-color: var(--bs-secondary-bg-subtle) !important
}

.bg-success-subtle {
    background-color: var(--bs-success-bg-subtle) !important
}

.bg-info-subtle {
    background-color: var(--bs-info-bg-subtle) !important
}

.bg-warning-subtle {
    background-color: var(--bs-warning-bg-subtle) !important
}

.bg-danger-subtle {
    background-color: var(--bs-danger-bg-subtle) !important
}

.bg-light-subtle {
    background-color: var(--bs-light-bg-subtle) !important
}

.bg-dark-subtle {
    background-color: var(--bs-dark-bg-subtle) !important
}

.bg-gradient {
    background-image: var(--bs-gradient) !important
}

.user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    user-select: all !important
}

.user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    user-select: auto !important
}

.user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important
}

.pe-none {
    pointer-events: none !important
}

.pe-auto {
    pointer-events: auto !important
}


.visible {
    visibility: visible !important
}

.invisible {
    visibility: hidden !important
}

.z-n1 {
    z-index: -1 !important
}

.z-0 {
    z-index: 0 !important
}

.z-1 {
    z-index: 1 !important
}

.z-2 {
    z-index: 2 !important
}

.z-3 {
    z-index: 3 !important
}

@include for-mobile {
    .m-m-0 {
        margin: 0 !important
    }
    
    .m-m-1 {
        margin: .25rem !important
    }
    
    .m-m-2 {
        margin: .5rem !important
    }
    
    .m-m-3 {
        margin: 1rem !important
    }
    
    .m-m-4 {
        margin: 1.5rem !important
    }
    
    .m-m-5 {
        margin: 3rem !important
    }
    
    .m-m-auto {
        margin: auto !important
    }
    
    .m-mx-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }
    
    .m-mx-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }
    
    .m-mx-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }
    
    .m-mx-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }
    
    .m-mx-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }
    
    .m-mx-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }
    
    .m-mx-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }
    
    .m-my-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }
    
    .m-my-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }
    
    .m-my-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }
    
    .m-my-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }
    
    .m-my-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }
    
    .m-my-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }
    
    .m-my-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }
    
    .m-mt-0 {
        margin-top: 0 !important
    }
    
    .m-mt-1 {
        margin-top: .25rem !important
    }
    
    .m-mt-2 {
        margin-top: .5rem !important
    }
    
    .m-mt-3 {
        margin-top: 1rem !important
    }
    
    .m-mt-4 {
        margin-top: 1.5rem !important
    }
    
    .m-mt-5 {
        margin-top: 3rem !important
    }
    
    .m-mt-auto {
        margin-top: auto !important
    }
    
    .m-me-0 {
        margin-right: 0 !important
    }
    
    .m-me-1 {
        margin-right: .25rem !important
    }
    
    .m-me-2 {
        margin-right: .5rem !important
    }
    
    .m-me-3 {
        margin-right: 1rem !important
    }
    
    .m-me-4 {
        margin-right: 1.5rem !important
    }
    
    .m-me-5 {
        margin-right: 3rem !important
    }
    
    .m-me-auto {
        margin-right: auto !important
    }
    
    .m-mb-0 {
        margin-bottom: 0 !important
    }
    
    .m-mb-1 {
        margin-bottom: .25rem !important
    }
    
    .m-mb-2 {
        margin-bottom: .5rem !important
    }
    
    .m-mb-3 {
        margin-bottom: 1rem !important
    }
    
    .m-mb-4 {
        margin-bottom: 1.5rem !important
    }
    
    .m-mb-5 {
        margin-bottom: 3rem !important
    }
    
    .m-mb-auto {
        margin-bottom: auto !important
    }
    
    .m-ms-0 {
        margin-left: 0 !important
    }
    
    .m-ms-1 {
        margin-left: .25rem !important
    }
    
    .m-ms-2 {
        margin-left: .5rem !important
    }
    
    .m-ms-3 {
        margin-left: 1rem !important
    }
    
    .m-ms-4 {
        margin-left: 1.5rem !important
    }
    
    .m-ms-5 {
        margin-left: 3rem !important
    }
    
    .m-ms-auto {
        margin-left: auto !important
    }
    
    .m-p-0 {
        padding: 0 !important
    }
    
    .m-p-1 {
        padding: .25rem !important
    }
    
    .m-p-2 {
        padding: .5rem !important
    }
    
    .m-p-3 {
        padding: 1rem !important
    }
    
    .m-p-4 {
        padding: 1.5rem !important
    }
    
    .m-p-5 {
        padding: 3rem !important
    }
    
    .m-px-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }
    
    .m-px-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }
    
    .m-px-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }
    
    .m-px-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }
    
    .m-px-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }
    
    .m-px-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }
    
    .m-py-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }
    
    .m-py-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }
    
    .m-py-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }
    
    .m-py-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }
    
    .m-py-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }
    
    .m-py-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }
    
    .m-pt-0 {
        padding-top: 0 !important
    }
    
    .m-pt-1 {
        padding-top: .25rem !important
    }
    
    .m-pt-2 {
        padding-top: .5rem !important
    }
    
    .m-pt-3 {
        padding-top: 1rem !important
    }
    
    .m-pt-4 {
        padding-top: 1.5rem !important
    }
    
    .m-pt-5 {
        padding-top: 3rem !important
    }
    
    .m-pe-0 {
        padding-right: 0 !important
    }
    
    .m-pe-1 {
        padding-right: .25rem !important
    }
    
    .m-pe-2 {
        padding-right: .5rem !important
    }
    
    .m-pe-3 {
        padding-right: 1rem !important
    }
    
    .m-pe-4 {
        padding-right: 1.5rem !important
    }
    
    .m-pe-5 {
        padding-right: 3rem !important
    }
    
    .m-pb-0 {
        padding-bottom: 0 !important
    }
    
    .m-pb-1 {
        padding-bottom: .25rem !important
    }
    
    .m-pb-2 {
        padding-bottom: .5rem !important
    }
    
    .m-pb-3 {
        padding-bottom: 1rem !important
    }
    
    .m-pb-4 {
        padding-bottom: 1.5rem !important
    }
    
    .m-pb-5 {
        padding-bottom: 3rem !important
    }
    
    .m-ps-0 {
        padding-left: 0 !important
    }
    
    .m-ps-1 {
        padding-left: .25rem !important
    }
    
    .m-ps-2 {
        padding-left: .5rem !important
    }
    
    .m-ps-3 {
        padding-left: 1rem !important
    }
    
    .m-ps-4 {
        padding-left: 1.5rem !important
    }
    
    .m-ps-5 {
        padding-left: 3rem !important
    }
}