:root {
    --bs-font-sans-serif: Arial, sans-serif;
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, monospace;
    --bs-blue: rgb(38, 3, 251);
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #f8e164;
    --bs-gold: #907631;
    --bs-green: #10994D;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-black-light: #0E0E0E;
    --bs-black: #000;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #A7A7A7;
    --bs-dark: #212529;
    --bs-primary-rgb: 13, 110, 253;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 0, 130, 50;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 4, 4, 8;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #adb5bd;
    --bs-body-color-rgb: 173, 181, 189;
    --bs-body-bg: #020202;
    --bs-body-bg-rgb: 33, 37, 41;
    --bs-bg-transparent-darkest: rgba(0, 0, 0, .95);
    --bs-bg-transparent-dark: rgba(0, 0, 0, .9);
    --bs-bg-transparent: rgba(0, 0, 0, .8);
    --bs-bg-transparent-2: rgb(0, 0, 0, .5);
    --bs-bg-transparent-3: rgba(0, 0, 0, .3);
    --bs-bg-transparent-4: rgba(0, 0, 0, .2);
    --bs-bg-transparent-5: rgba(0, 0, 0, .1);
    --bs-emphasis-color: #fff;
    --bs-emphasis-color-rgb: 255, 255, 255;
    --bs-secondary-color: rgba(173, 181, 189, 0.75);
    --bs-secondary-color-rgb: 173, 181, 189;
    --bs-secondary-bg: #343a40;
    --bs-secondary-bg-rgb: 52, 58, 64;
    --bs-tertiary-color: rgba(173, 181, 189, 0.5);
    --bs-tertiary-color-rgb: 173, 181, 189;
    --bs-tertiary-bg: #2b3035;
    --bs-tertiary-bg-rgb: 43, 48, 53;
    --bs-primary-text-emphasis: white;
    --bs-secondary-text-emphasis: #a7acb1;
    --bs-success-text-emphasis: #75b798;
    --bs-info-text-emphasis: #6edff6;
    --bs-warning-text-emphasis: #ffda6a;
    --bs-danger-text-emphasis: #ea868f;
    --bs-light-text-emphasis: #f8f9fa;
    --bs-dark-text-emphasis: #dee2e6;
    --bs-primary-bg-subtle: #121010;
    --bs-secondary-bg-subtle: #161719;
    --bs-success-bg-subtle: #051b11;
    --bs-info-bg-subtle: #032830;
    --bs-warning-bg-subtle: #332701;
    --bs-danger-bg-subtle: #2c0b0e;
    --bs-light-bg-subtle: #343a40;
    --bs-dark-bg-subtle: #1a1d20;
    --bs-primary-border-subtle: #084298;
    --bs-secondary-border-subtle: #41464b;
    --bs-success-border-subtle: #0f5132;
    --bs-info-border-subtle: #087990;
    --bs-warning-border-subtle: #997404;
    --bs-danger-border-subtle: #842029;
    --bs-light-border-subtle: #495057;
    --bs-dark-border-subtle: #343a40;
    --bs-heading-color: white;
    --bs-link-color: white;
    --bs-link-hover-color: #8bb9fe;
    --bs-link-color-rgb: 255, 255, 255;
    --bs-link-hover-color-rgb: 180, 180, 180;
    --bs-code-color: #e685b5;
    --bs-border-color: rgba(222, 222, 222, 0.1);
    --bs-transparent-bg: rgba(222, 222, 222, .025);
    --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
    --bs-form-valid-color: #75b798;
    --bs-form-valid-border-color: #75b798;
    --bs-form-invalid-color: #ea868f;
    --bs-form-invalid-border-color: #ea868f;
    --bs-form-input-background-color: var(--bs-transparent-bg);
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-radius: 4px;
    --bs-border-radius-second: 8px;
    --bs-border-radius-lg: 12px;
    --bs-more-border-radius: 16px;
    --action-gradient: linear-gradient(92deg, rgb(53, 26, 222) -0.98%, rgba(129, 26, 222, 0.51) 135.49%, rgba(207, 26, 222, 0.00) 135.51%), linear-gradient(0deg, #212121 -10%, #303030 100%);
    --action-gradient-border-left-color: rgb(53, 26, 222);
    --action-gradient-border-right-color: rgb(75, 30, 163);
    --navbar-gradient: linear-gradient(180deg, var(--bs-blue), rgba(0, 0, 255, 0.2), rgba(0, 0, 255, 0)), linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
    --navbar-boxshadow: 0px -1px 0px 0px var(--bs-blue) inset;
    --bg-blur: blur(20px);
    --bg-blur-light: blur(10px);
}

:root {
    --bs-breakpoint-xs: 0;
    --bs-breakpoint-sm: 576px;
    --bs-breakpoint-md: 768px;
    --bs-breakpoint-lg: 992px;
    --bs-breakpoint-xl: 1200px;
    --bs-breakpoint-xxl: 1400px
}