
.text-bg-primary {
    color: #fff !important;
    background-color: RGBA(13, 110, 253, var(--bs-bg-opacity, 1)) !important
}

.text-bg-secondary {
    color: #fff !important;
    background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important
}

.text-bg-success {
    color: #fff !important;
    background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important
}

.text-bg-info {
    color: #000 !important;
    background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important
}

.text-bg-warning {
    color: #000 !important;
    background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important
}

.text-bg-danger {
    color: #fff !important;
    background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important
}

.text-bg-light {
    color: #000 !important;
    background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important
}

.text-bg-dark {
    color: #fff !important;
    background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important
}

.link-primary {
    color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-primary:focus,
.link-primary:hover {
    color: RGBA(10, 88, 202, var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important
}

.link-secondary {
    color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-secondary:focus,
.link-secondary:hover {
    color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important
}

.link-success {
    color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-success:focus,
.link-success:hover {
    color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important
}

.link-info {
    color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-info:focus,
.link-info:hover {
    color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important
}

.link-warning {
    color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-warning:focus,
.link-warning:hover {
    color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important
}

.link-danger {
    color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-danger:focus,
.link-danger:hover {
    color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important
}

.link-light {
    color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-light:focus,
.link-light:hover {
    color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important
}

.link-dark {
    color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-dark:focus,
.link-dark:hover {
    color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important
}

.link-body-emphasis {
    color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-body-emphasis:focus,
.link-body-emphasis:hover {
    color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, .75)) !important;
    -webkit-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
    text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important
}

.focus-ring:focus {
    outline: 0;
    box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color)
}


.text-decoration-none {
    text-decoration: none !important
}

.text-decoration-underline {
    text-decoration: underline !important
}

.text-decoration-line-through {
    text-decoration: line-through !important
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.text-wrap {
    white-space: normal !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-break {
    word-wrap: break-word !important;
    word-break: break-word !important
}

.text-primary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important
}

.text-secondary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important
}

.text-success {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important
}

.text-info {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important
}

.text-warning {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important
}

.text-danger {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important
}

.text-light {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important
}

.text-dark {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important
}

.text-black {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important
}

.text-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important
}

.text-body {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important
}

.text-muted {
    --bs-text-opacity: 1;
    color: var(--bs-secondary-color) !important
}

.text-black-50 {
    --bs-text-opacity: 1;
    color: rgba(0, 0, 0, .5) !important
}

.text-white-50 {
    --bs-text-opacity: 1;
    color: rgba(255, 255, 255, .5) !important
}

.text-body-secondary {
    --bs-text-opacity: 1;
    color: var(--bs-secondary-color) !important
}

.text-body-tertiary {
    --bs-text-opacity: 1;
    color: var(--bs-tertiary-color) !important
}

.text-body-emphasis {
    --bs-text-opacity: 1;
    color: var(--bs-emphasis-color) !important
}

.text-reset {
    --bs-text-opacity: 1;
    color: inherit !important
}

.text-opacity-25 {
    --bs-text-opacity: 0.25
}

.text-opacity-50 {
    --bs-text-opacity: 0.5
}

.text-opacity-75 {
    --bs-text-opacity: 0.75
}

.text-opacity-100 {
    --bs-text-opacity: 1
}

.text-primary-emphasis {
    color: var(--bs-primary-text-emphasis) !important
}

.text-secondary-emphasis {
    color: var(--bs-secondary-text-emphasis) !important
}

.text-success-emphasis {
    color: var(--bs-success-text-emphasis) !important
}

.text-info-emphasis {
    color: var(--bs-info-text-emphasis) !important
}

.text-warning-emphasis {
    color: var(--bs-warning-text-emphasis) !important
}

.text-danger-emphasis {
    color: var(--bs-danger-text-emphasis) !important
}

.text-light-emphasis {
    color: var(--bs-light-text-emphasis) !important
}

.text-dark-emphasis {
    color: var(--bs-dark-text-emphasis) !important
}

.link-opacity-10 {
    --bs-link-opacity: 0.1
}

.link-opacity-10-hover:hover {
    --bs-link-opacity: 0.1
}

.link-opacity-25 {
    --bs-link-opacity: 0.25
}

.link-opacity-25-hover:hover {
    --bs-link-opacity: 0.25
}

.link-opacity-50 {
    --bs-link-opacity: 0.5
}

.link-opacity-50-hover:hover {
    --bs-link-opacity: 0.5
}

.link-opacity-75 {
    --bs-link-opacity: 0.75
}

.link-opacity-75-hover:hover {
    --bs-link-opacity: 0.75
}

.link-opacity-100 {
    --bs-link-opacity: 1
}

.link-opacity-100-hover:hover {
    --bs-link-opacity: 1
}

.link-offset-1 {
    text-underline-offset: 0.125em !important
}

.link-offset-1-hover:hover {
    text-underline-offset: 0.125em !important
}

.link-offset-2 {
    text-underline-offset: 0.25em !important
}

.link-offset-2-hover:hover {
    text-underline-offset: 0.25em !important
}

.link-offset-3 {
    text-underline-offset: 0.375em !important
}

.link-offset-3-hover:hover {
    text-underline-offset: 0.375em !important
}

.link-underline-primary {
    --bs-link-underline-opacity: 1;
    -webkit-text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
    text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important
}

.link-underline-secondary {
    --bs-link-underline-opacity: 1;
    -webkit-text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
    text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important
}

.link-underline-success {
    --bs-link-underline-opacity: 1;
    -webkit-text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
    text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important
}

.link-underline-info {
    --bs-link-underline-opacity: 1;
    -webkit-text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
    text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important
}

.link-underline-warning {
    --bs-link-underline-opacity: 1;
    -webkit-text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
    text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important
}

.link-underline-danger {
    --bs-link-underline-opacity: 1;
    -webkit-text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
    text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important
}

.link-underline-light {
    --bs-link-underline-opacity: 1;
    -webkit-text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
    text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important
}

.link-underline-dark {
    --bs-link-underline-opacity: 1;
    -webkit-text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
    text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important
}

.link-underline {
    --bs-link-underline-opacity: 1;
    -webkit-text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-underline-opacity-0 {
    --bs-link-underline-opacity: 0
}

.link-underline-opacity-0-hover:hover {
    --bs-link-underline-opacity: 0
}

.link-underline-opacity-10 {
    --bs-link-underline-opacity: 0.1
}

.link-underline-opacity-10-hover:hover {
    --bs-link-underline-opacity: 0.1
}

.link-underline-opacity-25 {
    --bs-link-underline-opacity: 0.25
}

.link-underline-opacity-25-hover:hover {
    --bs-link-underline-opacity: 0.25
}

.link-underline-opacity-50 {
    --bs-link-underline-opacity: 0.5
}

.link-underline-opacity-50-hover:hover {
    --bs-link-underline-opacity: 0.5
}

.link-underline-opacity-75 {
    --bs-link-underline-opacity: 0.75
}

.link-underline-opacity-75-hover:hover {
    --bs-link-underline-opacity: 0.75
}

.link-underline-opacity-100 {
    --bs-link-underline-opacity: 1
}

.link-underline-opacity-100-hover:hover {
    --bs-link-underline-opacity: 1
}