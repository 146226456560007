.music-card {
    max-width: 1080px;
    margin: 0 auto 100px;
    .card-header {
        padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x) var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x-left);

        @include for-mobile {
            padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);

            .card-header-inner {
                .chi-left {
                    width: 33% !important;
                }
                .chi-center {
                    width: 33% !important;
                    text-align: center;
                }
                .chi-right {
                    width: 33% !important;
                    text-align: right !important;
                }
            }
        }

    }
    .card-body {
        position: relative;

        .revision-list {
            padding: var(--bs-accordion-btn-padding-y) var(--bs-card-cap-padding-x) var(--bs-accordion-btn-padding-y) var(--bs-card-cap-padding-x-left);
            box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);

            .dropdown-menu {
                --bs-dropdown-bg: var(--bs-bg-transparent);
                backdrop-filter: var(--bg-blur-light);
            }
            
            @include for-mobile {
                padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);

                .rl-left {
                    font-size: 14px;
                    line-height: 16px;
                }
    
                .rl-center {
                    .rlc-left {
                        width: 20% !important;

                        img {
                            margin-right: 0;
                        }
                    }
                    .rlc-right {
                        width: 80% !important;
                    }
                }
    
                .rl-center-text {
                    font-size: 14px;
                    line-height: 28px;
                }
            }

        }
    }
    .music-upload-body {
        min-height: 360px;
        &:hover {
            border: 4px solid var(--bs-card-border-color);
        }
    }
    .accordion {
        .accordion-header {
            .accordion-button {
                padding-right: var(--bs-card-cap-padding-x);

                @include for-mobile {
                    padding-right: var(--bs-card-cap-padding-x);

                    .accordion-header-inner {
                        .ahi-left {
                            width: 70% !important;
                        }
                        .ahi-right {
                            width: 30% !important;
                        }
                    }
                }

            }
        }
    }
}

.relativeLoading_modal {
    position: relative;
    min-height: 360px;
}
.relativeLoading_btn {
    position: relative;
    min-height: 40px;
}

.music-select-time {
    width: 240px;
    margin: 0 auto 2rem !important;
    .mst-input {
        text-align: center;
        input {
            text-align: center;
        }
    }
    .mst-text {
        text-align: center;
        width: 40px;
        flex: 0 0;
    }
}

.music-select-genre {
    width: 360px;
    margin: 0 auto 2rem !important;
    .genre-select {
        width: 120px;
    }
}

.swiper-container {
    position: relative;
    height: 0;
    pointer-events: none;
    img.icon.icon-swipe {
        position: absolute;
        top: 60px;
    }
}

@include for-mobile {
    .music-card .music-upload-body {
        min-height: 240px;

        .upload-desc {
            font-size: .75rem !important;
        }

        .upload-icon {
            img  {
                width: auto;
                height: 36px;
                object-fit: contain;
            }
        }
    }
    .mobile-track-title-btn-group {
        position: relative;
        .col-sm-9 {
            width: 100% !important;

            input {
                font-size: 16px !important;
                line-height: 30px !important;
            }
        }
        .col-sm-3 {
            position: absolute;
            right: 6px;
            top: 6px;
            width: 80px;
            button {
                padding: 6px !important;
                font-size: 14px !important;
            }
        }
    }

    .upload-popup-mobile-info-text {
        font-size: 70%;
    }
}