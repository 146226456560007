form {
    gap: 24px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    >div {
        width: 100%;
    }
}

.form-label {
    margin-bottom: .5rem
}

.col-form-label {
    padding-top: calc(.375rem + var(--bs-border-width));
    padding-bottom: calc(.375rem + var(--bs-border-width));
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5
}

.col-form-label-lg {
    padding-top: calc(.5rem + var(--bs-border-width));
    padding-bottom: calc(.5rem + var(--bs-border-width));
    font-size: 1.25rem
}

.col-form-label-sm {
    padding-top: calc(.25rem + var(--bs-border-width));
    padding-bottom: calc(.25rem + var(--bs-border-width));
    font-size: .875rem
}

.form-text {
    margin-top: .25rem;
    font-size: .875em;
    color: var(--bs-secondary-color)
}

.form-control {
    width: 100%;
    display: flex;
    padding: 12px;
    align-items: center;
    flex-shrink: 0;
    border-color: var(--bs-border-color);
    border-style: solid;
    border-width: 1px;
    background-color: var(--bs-transparent-bg);
    backdrop-filter: var(--bg-blur);
    color: white;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control {
        transition: none
    }
}

.form-control[type=file] {
    overflow: hidden
}

.form-control[type=file]:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control:focus {
    color: var(--bs-white);
    background-color: var(--bs-form-input-background-color);
    border-color: var(--bs-white);
    outline: 0;
    box-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover {
    border: var(--bs-border-width) solid var(--bs-border-color);
    caret-color: white;
    -webkit-text-fill-color: var(--bs-white);
    -webkit-box-shadow: 0 0 0px 1000px var(--bs-form-input-background-color) inset;
    transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill:focus,
textarea:-webkit-autofill:focus {
    border: var(--bs-border-width) solid var(--bs-white);
    -webkit-text-fill-color: var(--bs-white);
    -webkit-box-shadow: 0 0 0px 1000px var(--bs-form-input-background-color) inset;
    transition: background-color 5000s ease-in-out 0s;
}

.form-control::-webkit-date-and-time-value {
    min-width: 85px;
    height: 1.5em;
    margin: 0
}

.form-control::-webkit-datetime-edit {
    display: block;
    padding: 0
}

.form-control::-moz-placeholder {
    color: var(--bs-secondary-color);
    opacity: 1
}

.form-control::placeholder {
    color: var(--bs-secondary-color);
    opacity: 1
}

.form-control:disabled {
    background-color: var(--bs-secondary-bg);
    opacity: 1
}

.form-control::-webkit-file-upload-button {
    padding: .375rem .75rem;
    margin: -.375rem -.75rem;
    -webkit-margin-end: .75rem;
    margin-inline-end: .75rem;
    color: var(--bs-body-color);
    background-color: var(--bs-tertiary-bg);
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: var(--bs-border-width);
    border-radius: 0;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.form-control::file-selector-button {
    padding: .375rem .75rem;
    margin: -.375rem -.75rem;
    -webkit-margin-end: .75rem;
    margin-inline-end: .75rem;
    color: var(--bs-body-color);
    background-color: var(--bs-tertiary-bg);
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: var(--bs-border-width);
    border-radius: 0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control::-webkit-file-upload-button {
        -webkit-transition: none;
        transition: none
    }

    .form-control::file-selector-button {
        transition: none
    }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: var(--bs-secondary-bg)
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: var(--bs-secondary-bg)
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: .375rem 0;
    margin-bottom: 0;
    line-height: 1.5;
    color: var(--bs-body-color);
    background-color: transparent;
    border: solid transparent;
    border-width: var(--bs-border-width) 0
}

.form-control-plaintext:focus {
    outline: 0
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm {
    min-height: calc(1.5em + .5rem + calc(var(--bs-border-width) * 2));
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: var(--bs-border-radius-sm)
}

.form-control-sm::-webkit-file-upload-button {
    padding: .25rem .5rem;
    margin: -.25rem -.5rem;
    -webkit-margin-end: .5rem;
    margin-inline-end: .5rem
}

.form-control-sm::file-selector-button {
    padding: .25rem .5rem;
    margin: -.25rem -.5rem;
    -webkit-margin-end: .5rem;
    margin-inline-end: .5rem
}

.form-control-lg {
    min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: var(--bs-border-radius)
}

.form-control-lg::-webkit-file-upload-button {
    padding: .5rem 1rem;
    margin: -.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem
}

.form-control-lg::file-selector-button {
    padding: .5rem 1rem;
    margin: -.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem
}

textarea.form-control {
    min-height: calc(1.5em + .75rem + calc(var(--bs-border-width) * 2))
}

textarea.form-control-sm {
    min-height: calc(1.5em + .5rem + calc(var(--bs-border-width) * 2))
}

textarea.form-control-lg {
    min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2))
}

.form-control-color {
    width: 3rem;
    height: calc(1.5em + .75rem + calc(var(--bs-border-width) * 2));
    padding: .375rem
}

.form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control-color::-moz-color-swatch {
    border: 0 !important;
    border-radius: var(--bs-border-radius)
}

.form-control-color::-webkit-color-swatch {
    border: 0 !important;
    border-radius: var(--bs-border-radius)
}

.form-control-color.form-control-sm {
    height: calc(1.5em + .5rem + calc(var(--bs-border-width) * 2))
}

.form-control-color.form-control-lg {
    height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2))
}

.form-select {
    --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .form-select {
        transition: none
    }
}

.form-select:focus {
    border-color: var(--bs-white);
    outline: 0;
    box-shadow: none
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
    padding-right: .75rem;
    background-image: none
}

.form-select:disabled {
    background-color: var(--bs-secondary-bg)
}

.form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 var(--bs-body-color)
}

.form-select-sm {
    padding-top: .25rem;
    padding-bottom: .25rem;
    padding-left: .5rem;
    font-size: .875rem;
    border-radius: var(--bs-border-radius-sm)
}

.form-select-lg {
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    font-size: 1.25rem;
    border-radius: var(--bs-border-radius-lg)
}

[data-bs-theme=dark] .form-select {
    --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23adb5bd' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e")
}

.form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: .125rem
}

.form-check .form-check-input {
    float: left;
    margin-left: -1.5em
}

.form-check-reverse {
    padding-right: 1.5em;
    padding-left: 0;
    text-align: right
}

.form-check-reverse .form-check-input {
    float: right;
    margin-right: -1.5em;
    margin-left: 0
}

.form-check-input {
    --bs-form-check-bg: var(--bs-body-bg);
    width: 1em;
    height: 1em;
    margin-top: 0;
    vertical-align: top;
    background-color: var(--bs-form-check-bg);
    background-image: var(--bs-form-check-bg-image);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: var(--bs-border-width) solid var(--bs-border-color);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;

    & + label {
        transition: all .15s ease-in-out;
    }
}

.form-check-input[type=checkbox] {
    border-radius: .25em
}

.form-check-input[type=radio] {
    border-radius: 50%
}

.form-check-input:active {
    filter: brightness(90%)
}

.form-check-input:focus {
    border-color: var(--bs-white);
    outline: 0;
    box-shadow: none
}

.form-check-input:checked {
    background-color: var(--bs-green);
}

.form-check-input:checked[type=checkbox] {
    box-shadow: 2px 0px 10px 0px #083f20 inset, -1px 0px 5px 0px #202020 inset;
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");

    & + label {
        font-weight: 700;
        font-size: 14px;
    }
}

.form-check-input:checked[type=radio] {
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e")
}

.form-check-input[type=checkbox]:indeterminate {
    background-color: #0d6efd;
    border-color: #0d6efd;
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e")
}

.form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: .5
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    cursor: default;
    opacity: .5
}

.form-switch {
    padding-left: 2.5em
}

.form-switch .form-check-input {
    --bs-form-switch-bg: url("../../public/icons/switch-toggle.svg");
    width: 2em;
    margin-left: -2.5em;
    background-size: 1rem;
    background-image: var(--bs-form-switch-bg);
    background-position: 10% center;
    border-radius: 2em;
    transition: background-position .15s ease-in-out;
    border-color: transparent;
    box-shadow: -4px -2px 8px 0px #202020 inset, 2px 0px 2px 0px #202020 inset;
    cursor: pointer;
}

@media (prefers-reduced-motion:reduce) {
    .form-switch .form-check-input {
        transition: none
    }
}

.form-switch .form-check-input:focus {
    outline: none
}

.form-switch .form-check-input:checked {
    background-position: 90% center;
}

.form-switch.form-check-reverse {
    padding-right: 2.5em;
    padding-left: 0
}

.form-switch.form-check-reverse .form-check-input {
    margin-right: -2.5em;
    margin-left: 0
}

.form-switch.lg-switch {
    --var-switch-size: 3em;
    --var-switch-size-reverse: -3em;
    padding-left: var(--var-switch-size);
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;

    .form-check-input {
        width: var(--var-switch-size);
        margin-left: var(--var-switch-size-reverse);
        margin-right: calc(var(--var-switch-size) / 5);
        height: calc(var(--var-switch-size) / 1.6);
    }
}

.form-check-inline {
    display: inline-block;
    margin-right: 1rem
}

.btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.btn-check:disabled+.btn,
.btn-check[disabled]+.btn {
    pointer-events: none;
    filter: none;
    opacity: .65
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e")
}

.form-range {
    width: 100%;
    height: 1.5rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.form-range:focus {
    outline: 0
}

.form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.form-range::-moz-focus-outer {
    border: 0
}

.form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .form-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none
    }
}

.form-range::-webkit-slider-thumb:active {
    background-color: #b6d4fe
}

.form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: var(--bs-tertiary-bg);
    border-color: transparent;
    border-radius: 1rem
}

.form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .form-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none
    }
}

.form-range::-moz-range-thumb:active {
    background-color: #b6d4fe
}

.form-range::-moz-range-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: var(--bs-tertiary-bg);
    border-color: transparent;
    border-radius: 1rem
}

.form-range:disabled {
    pointer-events: none
}

.form-range:disabled::-webkit-slider-thumb {
    background-color: var(--bs-secondary-color)
}

.form-range:disabled::-moz-range-thumb {
    background-color: var(--bs-secondary-color)
}

.form-floating {
    position: relative
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
    height: calc(3.5rem + calc(var(--bs-border-width) * 2));
    min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
    line-height: 1.25
}

.form-floating>label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
    padding: 1rem .75rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: var(--bs-border-width) solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-floating>label {
        transition: none
    }
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext {
    padding: 1rem .75rem
}

.form-floating>.form-control-plaintext::-moz-placeholder,
.form-floating>.form-control::-moz-placeholder {
    color: transparent
}

.form-floating>.form-control-plaintext::placeholder,
.form-floating>.form-control::placeholder {
    color: transparent
}

.form-floating>.form-control-plaintext:not(:-moz-placeholder-shown),
.form-floating>.form-control:not(:-moz-placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control-plaintext:focus,
.form-floating>.form-control-plaintext:not(:placeholder-shown),
.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control-plaintext:-webkit-autofill,
.form-floating>.form-control:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-select {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label {
    color: rgba(var(--bs-body-color-rgb), .65);
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control-plaintext~label,
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    color: rgba(var(--bs-body-color-rgb), .65);
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label::after {
    position: absolute;
    inset: 1rem 0.375rem;
    z-index: -1;
    height: 1.5em;
    content: "";
    background-color: var(--bs-body-bg);
    border-radius: var(--bs-border-radius)
}

.form-floating>.form-control-plaintext~label::after,
.form-floating>.form-control:focus~label::after,
.form-floating>.form-control:not(:placeholder-shown)~label::after,
.form-floating>.form-select~label::after {
    position: absolute;
    inset: 1rem 0.375rem;
    z-index: -1;
    height: 1.5em;
    content: "";
    background-color: var(--bs-body-bg);
    border-radius: var(--bs-border-radius)
}

.form-floating>.form-control:-webkit-autofill~label {
    color: rgba(var(--bs-body-color-rgb), .65);
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control-plaintext~label {
    border-width: var(--bs-border-width) 0
}

.form-floating>:disabled~label {
    color: #6c757d
}

.form-floating>:disabled~label::after {
    background-color: var(--bs-secondary-bg)
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%
}

.input-group>.form-control,
.input-group>.form-floating,
.input-group>.form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0
}

.input-group>.form-control:focus,
.input-group>.form-floating:focus-within,
.input-group>.form-select:focus {
    z-index: 5
}

.input-group .btn {
    position: relative;
    z-index: 2
}

.input-group .btn:focus {
    z-index: 5
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    text-align: center;
    white-space: nowrap;
    background-color: var(--bs-tertiary-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius)
}

.input-group-lg>.btn,
.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: var(--bs-border-radius-lg)
}

.input-group-sm>.btn,
.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text {
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: var(--bs-border-radius-sm)
}

.input-group-lg>.form-select,
.input-group-sm>.form-select {
    padding-right: 3rem
}

.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select,
.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group.has-validation>.dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-control,
.input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-select,
.input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: calc(var(--bs-border-width) * -1);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group>.form-floating:not(:first-child)>.form-control,
.input-group>.form-floating:not(:first-child)>.form-select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: var(--bs-form-valid-color)
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    color: #fff;
    background-color: var(--bs-success);
    border-radius: var(--bs-border-radius)
}

.is-valid~.valid-feedback,
.is-valid~.valid-tooltip,
.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip {
    display: block
}

.form-control.is-valid,
.was-validated .form-control:valid {
    border-color: var(--bs-form-valid-border-color);
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
    border-color: var(--bs-form-valid-border-color);
    box-shadow: 0 0 0 .25rem rgba(var(--bs-success-rgb), .25)
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
    padding-right: calc(1.5em + .75rem);
    background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem)
}

.form-select.is-valid,
.was-validated .form-select:valid {
    border-color: var(--bs-form-valid-border-color)
}

.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    padding-right: 4.125rem;
    background-position: right .75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-select.is-valid:focus,
.was-validated .form-select:valid:focus {
    border-color: var(--bs-form-valid-border-color);
    box-shadow: 0 0 0 .25rem rgba(var(--bs-success-rgb), .25)
}

.form-control-color.is-valid,
.was-validated .form-control-color:valid {
    width: calc(3rem + calc(1.5em + .75rem))
}

.form-check-input.is-valid,
.was-validated .form-check-input:valid {
    border-color: var(--bs-form-valid-border-color)
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
    background-color: var(--bs-form-valid-color)
}

.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
    box-shadow: 0 0 0 .25rem rgba(var(--bs-success-rgb), .25)
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
    color: var(--bs-form-valid-color)
}

.form-check-inline .form-check-input~.valid-feedback {
    margin-left: .5em
}

.input-group>.form-control:not(:focus).is-valid,
.input-group>.form-floating:not(:focus-within).is-valid,
.input-group>.form-select:not(:focus).is-valid,
.was-validated .input-group>.form-control:not(:focus):valid,
.was-validated .input-group>.form-floating:not(:focus-within):valid,
.was-validated .input-group>.form-select:not(:focus):valid {
    z-index: 3
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: var(--bs-form-invalid-color)
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    color: #fff;
    background-color: var(--bs-danger);
    border-radius: var(--bs-border-radius)
}

.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip,
.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip {
    display: block
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: var(--bs-form-invalid-border-color);
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: var(--bs-form-invalid-border-color);
    box-shadow: 0 0 0 .25rem rgba(var(--bs-danger-rgb), .25)
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
    padding-right: calc(1.5em + .75rem);
    background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem)
}

.form-select.is-invalid,
.was-validated .form-select:invalid {
    border-color: var(--bs-form-invalid-border-color)
}

.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    padding-right: 4.125rem;
    background-position: right .75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-select.is-invalid:focus,
.was-validated .form-select:invalid:focus {
    border-color: var(--bs-form-invalid-border-color);
    box-shadow: 0 0 0 .25rem rgba(var(--bs-danger-rgb), .25)
}

.form-control-color.is-invalid,
.was-validated .form-control-color:invalid {
    width: calc(3rem + calc(1.5em + .75rem))
}

.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
    border-color: var(--bs-form-invalid-border-color)
}

.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
    background-color: var(--bs-form-invalid-color)
}

.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
    box-shadow: 0 0 0 .25rem rgba(var(--bs-danger-rgb), .25)
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
    color: var(--bs-form-invalid-color)
}

.form-check-inline .form-check-input~.invalid-feedback {
    margin-left: .5em
}

.input-group>.form-control:not(:focus).is-invalid,
.input-group>.form-floating:not(:focus-within).is-invalid,
.input-group>.form-select:not(:focus).is-invalid,
.was-validated .input-group>.form-control:not(:focus):invalid,
.was-validated .input-group>.form-floating:not(:focus-within):invalid,
.was-validated .input-group>.form-select:not(:focus):invalid {
    z-index: 4
}

@mixin can-toggle-branding($can-toggle-off-color: #fff,
    $can-toggle-on-color: #5fc054,
    $can-toggle-inactive-text: rgba(white, 0.5),
    $can-toggle-transition: cubic-bezier(0, 1, 0.5, 1)) {
    input[type="checkbox"] {
        &[disabled]~label {
            color: rgba($can-toggle-off-color, 0.5);
        }

        &:hover~label {
            color: darken($can-toggle-off-color, 5%);
        }

        &:checked {
            ~label {
                &:hover {
                    color: darken($can-toggle-on-color, 3%);
                }
            }
        }
    }

    label {

        .can-toggle__label-text {
            flex: 1;
        }

        .can-toggle__switch {
            background: var(--bs-transparent-bg);

            &:before {
                color: $can-toggle-inactive-text;
            }

            &:after {
                // Autoprefixer choked here, so making the prefixes explicit
                -webkit-transition: -webkit-transform 0.3s $can-toggle-transition;
                transition: transform 0.3s $can-toggle-transition;
                color: $can-toggle-off-color;
            }
        }

    }
}

@mixin can-toggle-appearance ($can-toggle-width: 134px,
    $can-toggle-height: 36px,
    $can-toggle-border-radius: 4px,
    $can-toggle-offset: 2px,
    $can-toggle-label-font-size: 14px,
    $can-toggle-switch-font-size: 12px,
    $can-toggle-shadow: 0 3px 3px rgba(black, 0.4)) {
    $can-toggle-switch-width: $can-toggle-width/2;

    input[type="checkbox"] {

        &:focus~label,
        &:hover~label {
            .can-toggle__switch {
                &:after {
                    box-shadow: $can-toggle-shadow;
                }
            }
        }

        &:checked {
            ~label {
                .can-toggle__switch {
                    &:after {
                        transform: translate3d($can-toggle-width - ($can-toggle-switch-width + $can-toggle-offset), 0, 0);
                    }
                }
            }

            &:focus,
            &:hover {
                ~label {
                    .can-toggle__switch {
                        &:after {
                            box-shadow: $can-toggle-shadow;
                        }
                    }
                }
            }
        }
    }

    label {
        font-size: $can-toggle-label-font-size;

        .can-toggle__switch {
            height: $can-toggle-height;
            flex: 0 0 $can-toggle-width;
            border-radius: $can-toggle-border-radius;

            &:before {
                left: $can-toggle-width/2;
                font-size: $can-toggle-switch-font-size;
                line-height: $can-toggle-height;
                width: $can-toggle-width/2;
                padding: 0 12px;
            }

            &:after {
                top: $can-toggle-offset;
                left: $can-toggle-offset;
                border-radius: $can-toggle-border-radius/2;
                width: $can-toggle-switch-width - $can-toggle-offset;
                line-height: $can-toggle-height - ($can-toggle-offset*2);
                font-size: $can-toggle-switch-font-size;
            }

            &:hover {
                &:after {
                    box-shadow: $can-toggle-shadow;
                }
            }
        }
    }
}



.can-toggle {
    position: relative;

    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    input[type="checkbox"] {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;

        &[disabled]~label {
            pointer-events: none;

            .can-toggle__switch {
                opacity: 0.4;
            }
        }

        &:checked {
            ~label {

                .can-toggle__switch {

                    &:before {
                        content: attr(data-unchecked);
                        left: 0;
                    }

                    &:after {
                        content: attr(data-checked);
                    }
                }
            }
        }
    }

    label {
        user-select: none;
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;

        .can-toggle__label-text {
            flex: 1;
            padding-left: 32px;
        }

        .can-toggle__switch {
            position: relative;

            &:before {
                content: attr(data-checked);
                position: absolute;
                top: 0;
                text-transform: uppercase;
                text-align: center;
            }

            &:after {
                content: attr(data-unchecked);
                position: absolute;
                z-index: 5;
                text-transform: uppercase;
                text-align: center;
                background: var(--bs-green);
                color: var(--bs-white);
                transform: translate3d(0, 0, 0);
            }

        }

    }

    // Default values for .can-toggle class
    @include can-toggle-branding;
    @include can-toggle-appearance;

    // Create toggles of different sizes by overriding the can-toggle-appearance mixin defaults
    &.can-toggle--size-small {
        @include can-toggle-appearance (90px, // Toggle width
            28px, // Toggle height
            2px, // Toggle border radius
            1px, // Offset (distance btw switch and box)
            13px, // Label font size
            10px, // Switch font size
            0 2px 2px rgba(black, 0.4) // Switch shadow on hover/focus
        );
    }

    &.can-toggle--size-large {
        @include can-toggle-appearance (160px, // Toggle width
            50px, // Toggle height
            4px, // Toggle border radius
            2px, // Offset (distance btw switch and box)
            14px, // Label font size
            14px, // Switch font size
            0 4px 4px rgba(black, 0.4) // Switch shadow on hover/focus
        );
    }

    // Or re-brand the toggle for different applications
    &.demo-rebrand-1 {
        @include can-toggle-branding (#b53e74, // Off state color
            #3d9c72, // On state color
            rgba(white, 0.6), // Off state text color
            ease-in-out // Transition timing function
        );
    }

    &.demo-rebrand-2 {
        @include can-toggle-branding (#fff, // Off state color
            #fff, // On state color
            rgba(white, 0.7), // Off state text color
            cubic-bezier(0.860, 0.000, 0.070, 1.000) // Transition timing function
        );
        @include can-toggle-appearance (200px, // Toggle width
            36px, // Toggle height
            36px, // Toggle border radius
            4px, // Offset (distance btw switch and box)
            12px, // Label font size
            12px, // Switch font size
            0 4px 4px rgba(black, 0.4) // Switch shadow on hover/focus
        );
    }

}