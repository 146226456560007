.mainPriceCard {
    max-width: 75rem;
    padding: 3em 1.5em 15rem;
    margin: auto;
}

.priceCard__TopHeading {
    color: var(--bs-light);
}

.mainPriceCard__heading {
    font-weight: 600;
    font-size: 2em;
    margin-bottom: 30px;
    text-align: center;
    color: #eceff1;
}

.mainPriceCard__subheading {
    font-weight: 400;
    margin: 10px auto 50px;
    max-width: 640px;
    text-align: center;
    color: #eceff1;
}

.priceCards {
    position: relative;
}

.priceCards__inner {
    display: flex;
    flex-wrap: nowrap;
    gap: 80px;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;
}

.priceCard {
    --flow-space: 0.5em;
    --hsl: var(--hue), var(--saturation), var(--lightness);
    position: relative;
    width: 100%;
    max-width: 860px;
    height: 640px;
    min-height: 640px;
    max-height: 640px;
    margin: auto;
    flex: 1 1 14rem;
    padding: 2.5em 3em;
    display: grid;
    grid-template-rows: auto auto auto 1fr;
    align-items: start;
    gap: 14px;
    color: #eceff1;
    background: var(--bs-transparent-bg);
    backdrop-filter: var(--bg-blur);
    border-radius: var(--bs-more-border-radius);
    border: 1px solid var(--bs-border-color);
}

.priceCard:nth-child(1) {
    --hue: 300;
    --saturation: 110%;
    --lightness: 52%;
}

.priceCard:nth-child(2) {
    --hue: 150;
    --saturation: 80%;
    --lightness: 52%;
    --opacity: 0;
}

.priceCard:nth-child(3) {
    --hue: 338.69;
    --saturation: 100%;
    --lightness: 48.04%;
}

.priceCard__desc {
    color: var(--bs-light);
    height: 72px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
}

.priceCard__bullets-wrapper {
    display: flex;
    justify-content: space-between;
}

.priceCard__bullets {
    line-height: 1.4;
    padding-left: 0;
    list-style: none;
    width: 48%;
    margin: 0;

    li {
        margin-bottom: 0;
        line-height: 21px;
        height: 40px;
        display: flex;
        align-items: center;

        span {
            color: var(--bs-green);
            font-weight: bold;
        }
    }
}

.priceCard__bullets li::before {
    content: "";
    --size: 24px;
    display: inline-block;
    background: url('../assets/check-icon.svg') no-repeat;
    background-size: var(--size);
    background-position: 0 0;
    width: var(--size);
    min-width: var(--size);
    height: var(--size);
    margin-right: 10px;
}

.priceCard__bullets-seeall, .priceCard__bullets-seeless {
    display: none;
}

.priceCard__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: space-between;

    .can-toggle {
        width: 200px;
    }
}

.priceCard__heading {
    font-size: 1.6em;
    font-weight: 600;
    margin: 0;
}

.pch_account-type {
    text-align: right;

    .priceCard__TopHeading {
        font-size: .75em;
    }

    .priceCard__heading {
        color: var(--bs-green);
        font-size: 1em;
    }
}

.priceCard__price {
    font-size: 2.5em;
    font-weight: 700;
    margin-bottom: 0;
    color: white;
    position: relative;
    margin-top: 20px;

    span.price__not {
        position: relative;
        color: var(--bs-gray);
        font-size: 0.5em;
        font-weight: 400;
        margin-left: 1rem;

        &:after {
            content: "";
            position: absolute;
            width: 110%;
            height: 1px;
            top: 50%;
            left: -2px;
            margin-top: -1px;
            background: var(--bs-body-color);
            transform: rotate(-15deg);
        }
    }

    span.price__prefix {
        margin-left: 0;
        font-size: 0.4em;
        font-weight: 400;
    }

}

.price-gift-badge {
    position: absolute;
    right: 0;
    top: -15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .pgb-text {
        font-size: 12px;
        max-width: 150px;

        .pgbt-color {
            font-weight: bold;
            color: var(--bs-green);
        }
    }

    .pgb-icon {
        img {
            width: auto;
            height: 70px;
        }
    }

    &.pgb-mobile {
        display: none;
    }
}

.priceCard__old-price_section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    position: absolute;
    top: -20px;
    left: 0;

    .priceCard__old-price {
        position: relative;
        font-size: 20px;
        color: var(--bs-gray);

        &:after {
            content: "";
            position: absolute;
            width: 110%;
            height: 1px;
            top: 50%;
            left: 0px;
            background: var(--bs-body-color);
            transform: rotate(-15deg);
        }
    }

    .priceCard-price-badges {
        font-size: 11px;
        border-radius: 20px;
        padding: 0px 10px;
        background: var(--bs-green);
        color: white;

        &.pcpb_alternate {
            background: var(--bs-border-color);
        }
    }

    .priceCard-price-badges {
        font-weight: bold;
    }
}

.priceCard__cta_info {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 12px;
    margin: auto;
    text-align: center;
    font-size: .8rem;
    color: var(--bs-danger);
}

.cta {
    display: block;
    align-self: end;
    margin: 0 0 0.5em 0;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background-color: var(--bs-blue);
    padding: 1rem;
    border-radius: var(--bs-border-radius);
    font-size: 1rem;
    font-weight: 600;

    &.priceCard__cta-disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.overlay {
    position: absolute;
    inset: 0;
    pointer-events: none;
    user-select: none;
    opacity: var(--opacity, 0);
    -webkit-mask: radial-gradient(25rem 25rem at var(--x) var(--y),
            #000 1%,
            transparent 50%);
    mask: radial-gradient(25rem 25rem at var(--x) var(--y),
            #000 1%,
            transparent 50%);
    transition: 400ms mask ease;
    will-change: mask;
}

.overlay .priceCard {
    background-color: hsla(var(--hue), var(--saturation), var(--lightness), 0.1);
    border-color: hsla(var(--hue), var(--saturation), var(--lightness), 1);
    box-shadow: 0 0 0 1px inset hsl(var(--hue), var(--saturation), var(--lightness));
}

.overlay .cta {
    display: block;
    grid-row: -1;
    width: 100%;
    background-image: linear-gradient(to right, #ffffff00 0%, hsl(var(--hue), var(--saturation), var(--lightness)) 20%, hsl(var(--hue), var(--saturation), var(--lightness)) 80%, #ffffff00 100%);
    box-shadow: 0 0 0 1px hsl(var(--hue), var(--saturation), var(--lightness));
}

:not(.overlay)>.priceCard {
    transition: 400ms background ease;
    will-change: background;
}

.payment-plan-toggle {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 4px;
    gap: 4px;
    border-radius: 36px;
    background: var(--bs-transparent-bg);

    .toggle-option {
        background: transparent;
        border: none;
        outline: none;
        font-weight: normal;
        line-height: 28px;
        font-size: 12px;
        border-radius: 36px;
        padding: 0px 15px;
        transition: all 0.3s cubic-bezier(0.86, 0, 0.07, 1);

        &:hover {
            opacity: 0.8;
            background-color: var(--bs-black-light);
        }

        &.active {
            background-color: var(--bs-green);
            color: var(--bs-white);
        }
    }
}

.quantity-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: absolute;
    bottom: 43px;
    left: 3rem;
    right: 3rem;
    z-index: 10;
    pointer-events: none;

    input {
        opacity: 0;
        visibility: hidden;

        &:focus {
            outline: none;
        }
    }

    button {
        padding: 10px 20px;
        border: none;
        background-color: transparent;
        color: var(--bs-white);
        font-size: 1rem;
        font-weight: 600;
        pointer-events: auto;
        transition: all .3s ease-in-out;

        &:hover {
            transform: scale(1.2);
        }

        &:focus {
            outline: none;
        }
    }
}

@include for-mobile {
    .mainPriceCard {
        padding: 2em 0.1em 15rem;
    }
    .mainPriceCard__heading {
        font-size: 1.5rem;
    }

    .mainPriceCard__subheading {
        margin: 10px auto 10px;
    }

    .priceCards__inner {
        position: relative;

        &.overlay {
            display: none;
        }

        .priceCard {
            padding: 1rem;
            height: auto;
            min-height: auto;
            max-height: none;

            .priceCard__header {
                display: flex;
                flex-direction: column-reverse;
                align-items: flex-start;
                gap: 15px;

                .pch_account-type {
                    text-align: left;
                }
            }

            .priceCard__price .price-gift-badge {
                display: none;
            }

            .priceCard__desc {
                height: 90px;
            }

            .priceCard__bullets-wrapper {
                display: flex;
                flex-direction: column;
                
                .priceCard__bullets {
                    width: 100%;

                    &.priceCard__bullets-short {
                        margin-bottom: 20px;
                    }

                    li {
                        font-size: 14px;
                    }
                }

                .priceCard__bullets-seeall, .priceCard__bullets-seeless {
                    display: block;
                    text-align: center;
                    font-size: 14px;
                    text-decoration: underline;
                    margin: 10px 0;
                    cursor: pointer;
                }

                .priceCard__bullets-seeless, .pcb-seconds {
                    display: none;
                }

                &.pcbw-subscription.show-all, &.pcbw-credit.show-all {
                    .priceCard__bullets-seeall {
                        display: none;
                    }
                    .priceCard__bullets-seeless, .pcb-seconds{
                        display: block;
                    }
                }
            }

            .price-gift-badge.pgb-mobile {
                display: flex;
                justify-content: space-between;
                position: relative;
                top: 0;
                max-width: 320px;
                margin: -15px auto 0;

                .pgb-text {
                    font-size: 14px;
                    max-width: none;
                }
            }
            
            .cta {
                padding: 1.1rem;
                font-size: .9rem;
            }

            .priceCard__cta_info {
                bottom: -35px;
            }
    
            .quantity-input {
                left: 4px;
                right: 4px;
                bottom: 19px;
            }
        }
    }
}