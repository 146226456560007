.fade {
    transition: opacity .15s linear
}

@media (prefers-reduced-motion:reduce) {
    .fade {
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

@media (prefers-reduced-motion:reduce) {
    .collapsing {
        transition: none
    }
}

.collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width .35s ease
}

@media (prefers-reduced-motion:reduce) {
    .collapsing.collapse-horizontal {
        transition: none
    }
}

.dropdown,
.dropdown-center,
.dropend,
.dropstart,
.dropup,
.dropup-center {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
    margin-left: 0
}

.dropdown-menu {
    --bs-dropdown-zindex: 1000;
    --bs-dropdown-min-width: 10rem;
    --bs-dropdown-padding-x: 0;
    --bs-dropdown-padding-y: 0.5rem;
    --bs-dropdown-spacer: 0.25rem;
    --bs-dropdown-font-size: 1rem;
    --bs-dropdown-color: var(--bs-body-color);
    --bs-dropdown-bg: var(--bs-bg-transparent-2);
    backdrop-filter: var(--bg-blur);
    --bs-dropdown-border-color: var(--bs-border-color-translucent);
    --bs-dropdown-border-radius: var(--bs-border-radius-second);
    --bs-dropdown-border-width: var(--bs-border-width);
    --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius-second) - var(--bs-border-width));
    --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
    --bs-dropdown-divider-margin-y: 0.5rem;
    --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    --bs-dropdown-link-color: var(--bs-body-color);
    --bs-dropdown-link-hover-color: var(--bs-body-color);
    --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
    --bs-dropdown-link-active-color: #fff;
    --bs-dropdown-link-active-bg: #0d6efd;
    --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
    --bs-dropdown-item-padding-x: 1rem;
    --bs-dropdown-item-padding-y: 0.25rem;
    --bs-dropdown-header-color: #6c757d;
    --bs-dropdown-header-padding-x: 1rem;
    --bs-dropdown-header-padding-y: 0.5rem;
    position: absolute;
    z-index: var(--bs-dropdown-zindex);
    display: none;
    min-width: var(--bs-dropdown-min-width);
    padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
    margin: 0;
    font-size: var(--bs-dropdown-font-size);
    color: var(--bs-dropdown-color);
    text-align: left;
    list-style: none;
    background-color: var(--bs-dropdown-bg);
    background-clip: padding-box;
    border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
    border-radius: var(--bs-dropdown-border-radius)
}

.dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--bs-dropdown-spacer)
}

.reverse-dropdown .dropdown-menu[data-bs-popper] {
    right: 0;
    left: auto;
}

.dropdown-menu-start {
    --bs-position: start
}

.dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0
}

.dropdown-menu-end {
    --bs-position: end
}

.dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto
}

@media (min-width:576px) {
    .dropdown-menu-sm-start {
        --bs-position: start
    }

    .dropdown-menu-sm-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-sm-end {
        --bs-position: end
    }

    .dropdown-menu-sm-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width:768px) {
    .dropdown-menu-md-start {
        --bs-position: start
    }

    .dropdown-menu-md-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-md-end {
        --bs-position: end
    }

    .dropdown-menu-md-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width:992px) {
    .dropdown-menu-lg-start {
        --bs-position: start
    }

    .dropdown-menu-lg-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-lg-end {
        --bs-position: end
    }

    .dropdown-menu-lg-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width:1200px) {
    .dropdown-menu-xl-start {
        --bs-position: start
    }

    .dropdown-menu-xl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xl-end {
        --bs-position: end
    }

    .dropdown-menu-xl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width:1400px) {
    .dropdown-menu-xxl-start {
        --bs-position: start
    }

    .dropdown-menu-xxl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xxl-end {
        --bs-position: end
    }

    .dropdown-menu-xxl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

.dropup .dropdown-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: var(--bs-dropdown-spacer)
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropend .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: var(--bs-dropdown-spacer)
}

.dropend .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid
}

.dropend .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropend .dropdown-toggle::after {
    vertical-align: 0
}

.dropstart .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: var(--bs-dropdown-spacer)
}

.dropstart .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: ""
}

.dropstart .dropdown-toggle::after {
    display: none
}

.dropstart .dropdown-toggle::before {
    display: inline-block;
    margin-right: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: .3em solid;
    border-bottom: .3em solid transparent
}

.dropstart .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropstart .dropdown-toggle::before {
    vertical-align: 0
}

.dropdown-divider {
    height: 0;
    margin: var(--bs-dropdown-divider-margin-y) 0;
    overflow: hidden;
    border-top: 1px solid var(--bs-dropdown-divider-bg);
    opacity: 1
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    clear: both;
    font-weight: 400;
    color: var(--bs-dropdown-link-color);
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    border-radius: var(--bs-dropdown-item-border-radius, 0)
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg)
}

.dropdown-item.active,
.dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--bs-blue)
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent
}

.dropdown-menu.show {
    display: block;
    z-index: 10001;
}

.dropdown-header {
    display: block;
    padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
    margin-bottom: 0;
    font-size: .875rem;
    color: var(--bs-dropdown-header-color);
    white-space: nowrap
}

.dropdown-item-text {
    display: block;
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    color: var(--bs-dropdown-link-color)
}

.dropdown-menu-dark {
    --bs-dropdown-color: #dee2e6;
    --bs-dropdown-bg: #343a40;
    --bs-dropdown-border-color: var(--bs-border-color-translucent);
    --bs-dropdown-box-shadow: ;
    --bs-dropdown-link-color: #dee2e6;
    --bs-dropdown-link-hover-color: #fff;
    --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
    --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
    --bs-dropdown-link-active-color: #fff;
    --bs-dropdown-link-active-bg: #0d6efd;
    --bs-dropdown-link-disabled-color: #adb5bd;
    --bs-dropdown-header-color: #adb5bd
}


.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}




.accordion {
    --bs-accordion-color: var(--bs-body-color);
    --bs-accordion-bg: var(--bs-body-bg);
    --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
    --bs-accordion-border-color: var(--bs-border-color);
    --bs-accordion-border-width: var(--bs-border-width);
    --bs-accordion-border-radius: var(--bs-border-radius);
    --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
    --bs-accordion-btn-padding-x: 3rem;
    --bs-accordion-btn-padding-y: 1rem;
    --bs-accordion-btn-color: var(--bs-body-color);
    --bs-accordion-btn-bg: var(--bs-primary-bg-subtle);
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.57564 8.57576C5.80995 8.34145 6.18985 8.34145 6.42417 8.57576L11.9999 14.1515L17.5756 8.57576C17.81 8.34145 18.1899 8.34145 18.4242 8.57576C18.6585 8.81007 18.6585 9.18997 18.4242 9.42429L12.4242 15.4243C12.1899 15.6586 11.81 15.6586 11.5756 15.4243L5.57564 9.42429C5.34132 9.18997 5.34132 8.81007 5.57564 8.57576Z' fill='%23A7A7A7'/%3e%3c/svg%3e");
    --bs-accordion-btn-icon-width: 1.25rem;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6 9L12 15L18 9' stroke='%23A7A7A7' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
    --bs-accordion-btn-focus-border-color: white;
    --bs-accordion-btn-focus-box-shadow: none;
    --bs-accordion-body-padding-x: 1.25rem;
    --bs-accordion-body-padding-y: 1rem;
    --bs-accordion-active-color: var(--bs-primary-text-emphasis);
    --bs-accordion-active-bg: var(--bs-primary-bg-subtle)
}

.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    font-size: 1rem;
    color: var(--bs-accordion-btn-color);
    text-align: left;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition)
}

@media (prefers-reduced-motion:reduce) {
    .accordion-button {
        transition: none
    }
}

.accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color)
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform)
}

.accordion-button::after {
    position: absolute;
    left: var(--bs-card-cap-padding-x);
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition)
}

@media (prefers-reduced-motion:reduce) {
    .accordion-button::after {
        transition: none
    }
}

.accordion-button:hover {
    z-index: 2
}

.accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow)
}

.accordion-header {
    margin-bottom: 0;
    background: var(--bs-bg-transparent-2);
}

.accordion-item {
    color: var(--bs-accordion-color);
    background-color: transparent;
    border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color)
}

.accordion-item:first-of-type {
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius)
}

/* .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: var(--bs-accordion-inner-border-radius);
    border-top-right-radius: var(--bs-accordion-inner-border-radius)
} */

.accordion-item:not(:first-of-type) {
    border-top: 0
}

.accordion-item:last-of-type {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius)
}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
    border-bottom-left-radius: var(--bs-accordion-inner-border-radius)
}

.accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius)
}

.accordion-body {
    padding: 0
}

.accordion-flush .accordion-collapse {
    border-width: 0
}

.accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0
}

.accordion-flush .accordion-item:first-child {
    border-top: 0
}

.accordion-flush .accordion-item:last-child {
    border-bottom: 0
}

.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 0
}

[data-bs-theme=dark] .accordion-button::after {
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}



.breadcrumb {
    --bs-breadcrumb-padding-x: 0;
    --bs-breadcrumb-padding-y: 0;
    --bs-breadcrumb-margin-bottom: 1rem;
    --bs-breadcrumb-bg: ;
    --bs-breadcrumb-border-radius: ;
    --bs-breadcrumb-divider-color: var(--bs-secondary-color);
    --bs-breadcrumb-item-padding-x: 0.5rem;
    --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
    display: flex;
    flex-wrap: wrap;
    padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
    margin-bottom: var(--bs-breadcrumb-margin-bottom);
    font-size: var(--bs-breadcrumb-font-size);
    list-style: none;
    background-color: var(--bs-breadcrumb-bg);
    border-radius: var(--bs-breadcrumb-border-radius)
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: var(--bs-breadcrumb-item-padding-x)
}

.breadcrumb-item+.breadcrumb-item::before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: var(--bs-breadcrumb-divider, "/")
}

.breadcrumb-item.active {
    color: var(--bs-breadcrumb-item-active-color)
}

.pagination {
    --bs-pagination-padding-x: 0.75rem;
    --bs-pagination-padding-y: 0.375rem;
    --bs-pagination-font-size: 1rem;
    --bs-pagination-color: var(--bs-link-color);
    --bs-pagination-bg: var(--bs-body-bg);
    --bs-pagination-border-width: var(--bs-border-width);
    --bs-pagination-border-color: var(--bs-border-color);
    --bs-pagination-border-radius: var(--bs-border-radius);
    --bs-pagination-hover-color: var(--bs-link-hover-color);
    --bs-pagination-hover-bg: var(--bs-tertiary-bg);
    --bs-pagination-hover-border-color: var(--bs-border-color);
    --bs-pagination-focus-color: var(--bs-link-hover-color);
    --bs-pagination-focus-bg: var(--bs-secondary-bg);
    --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --bs-pagination-active-color: #fff;
    --bs-pagination-active-bg: #0d6efd;
    --bs-pagination-active-border-color: #0d6efd;
    --bs-pagination-disabled-color: var(--bs-secondary-color);
    --bs-pagination-disabled-bg: var(--bs-secondary-bg);
    --bs-pagination-disabled-border-color: var(--bs-border-color);
    display: flex;
    padding-left: 0;
    list-style: none
}

.page-link {
    position: relative;
    display: block;
    padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
    font-size: var(--bs-pagination-font-size);
    color: var(--bs-pagination-color);
    text-decoration: none;
    background-color: var(--bs-pagination-bg);
    border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .page-link {
        transition: none
    }
}

.page-link:hover {
    z-index: 2;
    color: var(--bs-pagination-hover-color);
    background-color: var(--bs-pagination-hover-bg);
    border-color: var(--bs-pagination-hover-border-color)
}

.page-link:focus {
    z-index: 3;
    color: var(--bs-pagination-focus-color);
    background-color: var(--bs-pagination-focus-bg);
    outline: 0;
    box-shadow: var(--bs-pagination-focus-box-shadow)
}

.active>.page-link,
.page-link.active {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: var(--bs-pagination-active-bg);
    border-color: var(--bs-pagination-active-border-color)
}

.disabled>.page-link,
.page-link.disabled {
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color)
}

.page-item:not(:first-child) .page-link {
    margin-left: calc(var(--bs-border-width) * -1)
}

.page-item:first-child .page-link {
    border-top-left-radius: var(--bs-pagination-border-radius);
    border-bottom-left-radius: var(--bs-pagination-border-radius)
}

.page-item:last-child .page-link {
    border-top-right-radius: var(--bs-pagination-border-radius);
    border-bottom-right-radius: var(--bs-pagination-border-radius)
}

.pagination-lg {
    --bs-pagination-padding-x: 1.5rem;
    --bs-pagination-padding-y: 0.75rem;
    --bs-pagination-font-size: 1.25rem;
    --bs-pagination-border-radius: var(--bs-border-radius-lg)
}

.pagination-sm {
    --bs-pagination-padding-x: 0.5rem;
    --bs-pagination-padding-y: 0.25rem;
    --bs-pagination-font-size: 0.875rem;
    --bs-pagination-border-radius: var(--bs-border-radius-sm)
}

.badge {
    --bs-badge-padding-x: 0.65em;
    --bs-badge-padding-y: 0.35em;
    --bs-badge-font-size: 0.75em;
    --bs-badge-font-weight: 700;
    --bs-badge-color: #fff;
    --bs-badge-border-radius: var(--bs-border-radius);
    display: inline-block;
    padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
    font-size: var(--bs-badge-font-size);
    font-weight: var(--bs-badge-font-weight);
    line-height: 1;
    color: var(--bs-badge-color);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: var(--bs-badge-border-radius)
}

.badge:empty {
    display: none
}

.badge.badge-success {
    background-color: var(--bs-success);
}

.badge.badge-warning {
    background-color: var(--bs-warning);
    color: var(--bs-gray-dark);
}

.badge.badge-light {
    background-color: var(--bs-gray-dark);
}

.badge.badge-dark {
    background-color: var(--bs-secondary-bg-subtle);
}

.btn .badge {
    position: relative;
    top: -1px
}

@keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem
    }
}

.progress,
.progress-stacked {
    --bs-progress-height: 1rem;
    --bs-progress-font-size: 0.75rem;
    --bs-progress-bg: var(--bs-secondary-bg);
    --bs-progress-border-radius: var(--bs-border-radius);
    --bs-progress-box-shadow: var(--bs-box-shadow-inset);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #0d6efd;
    --bs-progress-bar-transition: width 0.6s ease;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius)
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: var(--bs-progress-bar-color);
    text-align: center;
    white-space: nowrap;
    background-color: var(--bs-progress-bar-bg);
    transition: var(--bs-progress-bar-transition)
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar {
        transition: none
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-size: var(--bs-progress-height) var(--bs-progress-height)
}

.progress-stacked>.progress {
    overflow: visible
}

.progress-stacked>.progress>.progress-bar {
    width: 100%
}

.progress-bar-animated {
    animation: 1s linear infinite progress-bar-stripes
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar-animated {
        animation: none
    }
}



.carousel {
    position: relative
}

.carousel.pointer-event {
    touch-action: pan-y
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: ""
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .carousel-item {
        transition: none
    }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: block
}

.active.carousel-item-end,
.carousel-item-next:not(.carousel-item-start) {
    transform: translateX(100%)
}

.active.carousel-item-start,
.carousel-item-prev:not(.carousel-item-end) {
    transform: translateX(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none
}

.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end,
.carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1
}

.carousel-fade .active.carousel-item-end,
.carousel-fade .active.carousel-item-start {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s .6s
}

@media (prefers-reduced-motion:reduce) {

    .carousel-fade .active.carousel-item-end,
    .carousel-fade .active.carousel-item-start {
        transition: none
    }
}

.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease
}

@media (prefers-reduced-motion:reduce) {

    .carousel-control-next,
    .carousel-control-prev {
        transition: none
    }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease
}

@media (prefers-reduced-motion:reduce) {
    .carousel-indicators [data-bs-target] {
        transition: none
    }
}

.carousel-indicators .active {
    opacity: 1
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center
}

.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
    filter: invert(1) grayscale(100)
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #000
}

.carousel-dark .carousel-caption {
    color: #000
}

[data-bs-theme=dark] .carousel .carousel-control-next-icon,
[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon,
[data-bs-theme=dark].carousel .carousel-control-prev-icon {
    filter: invert(1) grayscale(100)
}

[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target],
[data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
    background-color: #000
}

[data-bs-theme=dark] .carousel .carousel-caption,
[data-bs-theme=dark].carousel .carousel-caption {
    color: #000
}

.spinner-border,
.spinner-grow {
    display: inline-block;
    width: var(--bs-spinner-width);
    height: var(--bs-spinner-height);
    vertical-align: var(--bs-spinner-vertical-align);
    border-radius: 50%;
    animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name)
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg)
    }
}

.spinner-border {
    --bs-spinner-width: 2rem;
    --bs-spinner-height: 2rem;
    --bs-spinner-vertical-align: -0.125em;
    --bs-spinner-border-width: 0.25em;
    --bs-spinner-animation-speed: 0.75s;
    --bs-spinner-animation-name: spinner-border;
    border: var(--bs-spinner-border-width) solid currentcolor;
    border-right-color: transparent
}

.spinner-border-sm {
    --bs-spinner-width: 1rem;
    --bs-spinner-height: 1rem;
    --bs-spinner-border-width: 0.2em
}

@keyframes spinner-grow {
    0% {
        transform: scale(0)
    }

    50% {
        opacity: 1;
        transform: none
    }
}

.spinner-grow {
    --bs-spinner-width: 2rem;
    --bs-spinner-height: 2rem;
    --bs-spinner-vertical-align: -0.125em;
    --bs-spinner-animation-speed: 0.75s;
    --bs-spinner-animation-name: spinner-grow;
    background-color: currentcolor;
    opacity: 0
}

.spinner-grow-sm {
    --bs-spinner-width: 1rem;
    --bs-spinner-height: 1rem
}

@media (prefers-reduced-motion:reduce) {

    .spinner-border,
    .spinner-grow {
        --bs-spinner-animation-speed: 1.5s
    }
}

.offcanvas,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm,
.offcanvas-xl,
.offcanvas-xxl {
    --bs-offcanvas-zindex: 1045;
    --bs-offcanvas-width: 400px;
    --bs-offcanvas-height: 30vh;
    --bs-offcanvas-padding-x: 1rem;
    --bs-offcanvas-padding-y: 1rem;
    --bs-offcanvas-color: var(--bs-body-color);
    --bs-offcanvas-bg: var(--bs-body-bg);
    --bs-offcanvas-border-width: var(--bs-border-width);
    --bs-offcanvas-border-color: var(--bs-border-color-translucent);
    --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    --bs-offcanvas-transition: transform 0.3s ease-in-out;
    --bs-offcanvas-title-line-height: 1.5
}

@media (max-width:575.98px) {
    .offcanvas-sm {
        position: fixed;
        bottom: 0;
        z-index: var(--bs-offcanvas-zindex);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: var(--bs-offcanvas-transition)
    }
}

@media (max-width:575.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-sm {
        transition: none
    }
}

@media (max-width:575.98px) {
    .offcanvas-sm.offcanvas-start {
        top: 0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(-100%)
    }

    .offcanvas-sm.offcanvas-end {
        top: 0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(100%)
    }

    .offcanvas-sm.offcanvas-top {
        top: 0;
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(-100%)
    }

    .offcanvas-sm.offcanvas-bottom {
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(100%)
    }

    .offcanvas-sm.show:not(.hiding),
    .offcanvas-sm.showing {
        transform: none
    }

    .offcanvas-sm.hiding,
    .offcanvas-sm.show,
    .offcanvas-sm.showing {
        visibility: visible
    }
}

@media (min-width:576px) {
    .offcanvas-sm {
        --bs-offcanvas-height: auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent !important
    }

    .offcanvas-sm .offcanvas-header {
        display: none
    }

    .offcanvas-sm .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent !important
    }
}

@media (max-width:767.98px) {
    .offcanvas-md {
        position: fixed;
        bottom: 0;
        z-index: var(--bs-offcanvas-zindex);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: var(--bs-offcanvas-transition)
    }
}

@media (max-width:767.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-md {
        transition: none
    }
}

@media (max-width:767.98px) {
    .offcanvas-md.offcanvas-start {
        top: 0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(-100%)
    }

    .offcanvas-md.offcanvas-end {
        top: 0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(100%)
    }

    .offcanvas-md.offcanvas-top {
        top: 0;
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(-100%)
    }

    .offcanvas-md.offcanvas-bottom {
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(100%)
    }

    .offcanvas-md.show:not(.hiding),
    .offcanvas-md.showing {
        transform: none
    }

    .offcanvas-md.hiding,
    .offcanvas-md.show,
    .offcanvas-md.showing {
        visibility: visible
    }
}

@media (min-width:768px) {
    .offcanvas-md {
        --bs-offcanvas-height: auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent !important
    }

    .offcanvas-md .offcanvas-header {
        display: none
    }

    .offcanvas-md .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent !important
    }
}

@media (max-width:991.98px) {
    .offcanvas-lg {
        position: fixed;
        bottom: 0;
        z-index: var(--bs-offcanvas-zindex);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: var(--bs-offcanvas-transition)
    }
}

@media (max-width:991.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-lg {
        transition: none
    }
}

@media (max-width:991.98px) {
    .offcanvas-lg.offcanvas-start {
        top: 0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(-100%)
    }

    .offcanvas-lg.offcanvas-end {
        top: 0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(100%)
    }

    .offcanvas-lg.offcanvas-top {
        top: 0;
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(-100%)
    }

    .offcanvas-lg.offcanvas-bottom {
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(100%)
    }

    .offcanvas-lg.show:not(.hiding),
    .offcanvas-lg.showing {
        transform: none
    }

    .offcanvas-lg.hiding,
    .offcanvas-lg.show,
    .offcanvas-lg.showing {
        visibility: visible
    }
}

@media (min-width:992px) {
    .offcanvas-lg {
        --bs-offcanvas-height: auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent !important
    }

    .offcanvas-lg .offcanvas-header {
        display: none
    }

    .offcanvas-lg .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent !important
    }
}

@media (max-width:1199.98px) {
    .offcanvas-xl {
        position: fixed;
        bottom: 0;
        z-index: var(--bs-offcanvas-zindex);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: var(--bs-offcanvas-transition)
    }
}

@media (max-width:1199.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-xl {
        transition: none
    }
}

@media (max-width:1199.98px) {
    .offcanvas-xl.offcanvas-start {
        top: 0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(-100%)
    }

    .offcanvas-xl.offcanvas-end {
        top: 0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(100%)
    }

    .offcanvas-xl.offcanvas-top {
        top: 0;
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(-100%)
    }

    .offcanvas-xl.offcanvas-bottom {
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(100%)
    }

    .offcanvas-xl.show:not(.hiding),
    .offcanvas-xl.showing {
        transform: none
    }

    .offcanvas-xl.hiding,
    .offcanvas-xl.show,
    .offcanvas-xl.showing {
        visibility: visible
    }
}

@media (min-width:1200px) {
    .offcanvas-xl {
        --bs-offcanvas-height: auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent !important
    }

    .offcanvas-xl .offcanvas-header {
        display: none
    }

    .offcanvas-xl .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent !important
    }
}

@media (max-width:1399.98px) {
    .offcanvas-xxl {
        position: fixed;
        bottom: 0;
        z-index: var(--bs-offcanvas-zindex);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: var(--bs-offcanvas-transition)
    }
}

@media (max-width:1399.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-xxl {
        transition: none
    }
}

@media (max-width:1399.98px) {
    .offcanvas-xxl.offcanvas-start {
        top: 0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(-100%)
    }

    .offcanvas-xxl.offcanvas-end {
        top: 0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(100%)
    }

    .offcanvas-xxl.offcanvas-top {
        top: 0;
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(-100%)
    }

    .offcanvas-xxl.offcanvas-bottom {
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(100%)
    }

    .offcanvas-xxl.show:not(.hiding),
    .offcanvas-xxl.showing {
        transform: none
    }

    .offcanvas-xxl.hiding,
    .offcanvas-xxl.show,
    .offcanvas-xxl.showing {
        visibility: visible
    }
}

@media (min-width:1400px) {
    .offcanvas-xxl {
        --bs-offcanvas-height: auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent !important
    }

    .offcanvas-xxl .offcanvas-header {
        display: none
    }

    .offcanvas-xxl .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent !important
    }
}

.offcanvas {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition)
}

@media (prefers-reduced-motion:reduce) {
    .offcanvas {
        transition: none
    }
}

.offcanvas.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%)
}

.offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%)
}

.offcanvas.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%)
}

.offcanvas.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%)
}

.offcanvas.show:not(.hiding),
.offcanvas.showing {
    transform: none
}

.offcanvas.hiding,
.offcanvas.show,
.offcanvas.showing {
    visibility: visible
}

.offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000
}

.offcanvas-backdrop.fade {
    opacity: 0
}

.offcanvas-backdrop.show {
    opacity: .5
}

.offcanvas-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x)
}

.offcanvas-header .btn-close {
    padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
    margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
    margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
    margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y))
}

.offcanvas-title {
    margin-bottom: 0;
    line-height: var(--bs-offcanvas-title-line-height)
}

.offcanvas-body {
    flex-grow: 1;
    padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
    overflow-y: auto
}

.placeholder {
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    cursor: wait;
    background-color: currentcolor;
    opacity: .5
}

.placeholder.btn::before {
    display: inline-block;
    content: ""
}

.placeholder-xs {
    min-height: .6em
}

.placeholder-sm {
    min-height: .8em
}

.placeholder-lg {
    min-height: 1.2em
}

.placeholder-glow .placeholder {
    animation: placeholder-glow 2s ease-in-out infinite
}

@keyframes placeholder-glow {
    50% {
        opacity: .2
    }
}

.placeholder-wave {
    -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
    mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
    -webkit-mask-size: 200% 100%;
    mask-size: 200% 100%;
    animation: placeholder-wave 2s linear infinite
}

@keyframes placeholder-wave {
    100% {
        -webkit-mask-position: -200% 0%;
        mask-position: -200% 0%
    }
}

/* error page */
.error-page {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    min-height: calc(100% - 50px);

    @keyframes ol-500-text--entrance {
        0% {
            opacity: 0;
            transform: scale(1.2);
        }
    }

    @keyframes ol-500-text--flicker {

        0%,
        29% {
            opacity: .2;
        }

        30%,
        34% {
            opacity: .4;
        }

        35%,
        39% {
            opacity: .1;
        }

        40%,
        49% {
            opacity: .2;
        }

        50%,
        100% {
            opacity: .1;
        }
    }

    @keyframes ol-500-text-letter--flicker {

        0%,
        9% {
            opacity: 1;
        }

        10%,
        29% {
            opacity: .5;
        }

        30%,
        34% {
            opacity: 1;
        }

        35%,
        49% {
            opacity: 0;
        }

        50%,
        100% {
            opacity: 1;
        }
    }

    @keyframes ol-500-image--flicker {

        0%,
        9% {
            opacity: .2;
        }

        10%,
        29% {
            opacity: .5;
        }

        30%,
        34% {
            opacity: .2;
        }

        35%,
        49% {
            opacity: .4;
        }

        50%,
        59% {
            opacity: .3;
        }

        60%,
        74% {
            opacity: .5;
        }

        75%,
        89% {
            opacity: .2;
        }

        90%,
        100% {
            opacity: .4;
        }
    }

    &-image {
        position: absolute;
        top: 5vmin;
        left: 0;
        right: 0;
        margin: auto;
        width: 320px;
        opacity: .2;
        animation: ol-500-image--flicker 2.5s ease infinite .2s;

        img {
            margin: auto;
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }

    &-text {
        display: flex;
        position: absolute;
        top: 35vmin;
        left: 0;
        right: 0;
        justify-content: center;
        align-items: center;
        font-size: 28vmin;
        font-weight: 700;
        color: darkgray;
        z-index: 1;
        animation: ol-500-text--flicker 3s ease infinite;

        span {
            &:nth-child(2) {
                animation: ol-500-text-letter--flicker 1.5s ease infinite .2s;
            }

            &:nth-child(3) {
                animation: ol-500-text-letter--flicker 2s ease infinite .1s;
            }
        }
    }

    &-terminal {
        position: absolute;
        color: limegreen;
        top: auto;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        padding: 1rem;

        @keyframes ol-500-terminal-line--animate {
            0% {
                width: 0%;
            }
        }

        @keyframes ol-500-terminal-line--entrance {
            0% {
                opacity: 0;
                transform: translateY(10px);
            }
        }

        div {
            overflow: hidden;
            margin: .2rem 0;
            white-space: nowrap;
            font: 16px monospace;
            letter-spacing: 2px;

            animation: ol-500-terminal-line--animate 4s steps(100, end), ol-500-terminal-line--entrance .3s ease;
            animation-fill-mode: backwards;

            @for $i from 0 to 10 {
                &:nth-child(#{$i + 1}) {
                    animation-delay: #{$i * 1}s;
                }
            }

            &:last-child {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
}

.mouse-dot {
    visibility: hidden;
    position: fixed;
    width: 80px;
    height: 80px;
    margin-left: -5px;
    margin-top: -5px;
    border-radius: 100%;
    filter: blur(40px);
    overflow: hidden;
    z-index: 100000;
    pointer-events: none;
    opacity: .33;
    background: var(--bs-blue);
}