
*,
::after,
::before {
    box-sizing: border-box
}

/* scrolbar */
::-webkit-scrollbar-track
{
	box-shadow: none;
	-webkit-box-shadow: none;
	background-color: #181818;
}
::-webkit-scrollbar-thumb
{
	background-color: #060606;
}
::-webkit-scrollbar
{
	width: 6px;
	background-color: #181818;
}

@media (prefers-reduced-motion:no-preference) {
    :root {
        scroll-behavior: smooth
    }
}

html {
    overflow-x: hidden;
}

body {
    margin: 0;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-light);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

.main-bg-right-top {
    position: fixed;
    pointer-events: none;
    width: 40vw;
    height: 40vw;
    top: -150px;
    left: 55%;
    opacity: .05;
    z-index: -1;
    border-radius: 50%;
    background: var(--bs-blue);
    filter: blur(125px);

    @include for-mobile {
        width: 60vw;
        height: 60vw;
        top: 0;
        left: auto;
        right: 0;
        opacity: .33;
    }
}

.main-bg-left-bottom {
    position: absolute;
    pointer-events: none;
    width: 100px;
    height: 100vh;
    top: 0%;
    left: 5%;
    opacity: .02;
    z-index: 0;
    border-radius: 50%;
    background: var(--bs-light);
    filter: blur(20px);
    transform: rotate(50deg);

    &.mbglb-second {
        top: 40%;
        left: 0%;
    }
}

hr {
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: var(--bs-border-width) solid;
    opacity: .25
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
    color: var(--bs-heading-color)
}

.h1,
h1 {
    font-size: calc(1.375rem + 1.5vw)
}

@media (min-width:1200px) {
    .h1,
    h1 {
        font-size: 2rem;
    }
}

.h2,
h2 {
    font-size: calc(1.325rem + .9vw)
}

@media (min-width:1200px) {
    .h2,
    h2 {
        font-size: 1.75rem
    }
}

.h3,
h3 {
    font-size: calc(1.3rem + .6vw)
}

@media (min-width:1200px) {
    .h3,
    h3 {
        font-size: 1.50rem
    }
}

.h4,
h4 {
    font-size: calc(1.275rem + .3vw)
}

@media (min-width:1200px) {
    .h4,
    h4 {
        font-size: 1.25rem
    }
}

.h5,
h5 {
    font-size: 1.25rem
}

.h6,
h6 {
    font-size: 1rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

abbr[title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

ol,
ul {
    padding-left: 2rem
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 700
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

b,
strong {
    font-weight: bolder
}

.small,
small {
    font-size: .75em
}

.mark,
mark {
    padding: .1875em;
    background-color: var(--bs-highlight-bg)
}

sub,
sup {
    position: relative;
    font-size: .75em;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
    text-decoration: none;
}

a:hover {
    --bs-link-color-rgb: var(--bs-link-hover-color-rgb)
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none
}

code,
kbd,
pre,
samp {
    font-family: var(--bs-font-monospace);
    font-size: 1em
}

pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: .875em
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
}

code {
    font-size: .875em;
    color: var(--bs-code-color);
    word-wrap: break-word
}

a>code {
    color: inherit
}

kbd {
    padding: .1875rem .375rem;
    font-size: .875em;
    color: var(--bs-body-bg);
    background-color: var(--bs-body-color);
    border-radius: .25rem
}

kbd kbd {
    padding: 0;
    font-size: 1em
}

figure {
    margin: 0 0 1rem
}

img,
svg {
    vertical-align: middle
}

table {
    caption-side: bottom;
    border-collapse: collapse
}

caption {
    padding-top: .5rem;
    padding-bottom: .5rem;
    color: var(--bs-secondary-color);
    text-align: left
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent
}

tbody,
td,
tfoot,
th,
thead,
tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0
}

label {
    display: inline-block;
    color: var(--bs-gray-500);
    font-size: 12px;
}

button:focus:not(:focus-visible) {
    outline: 0
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,
select {
    text-transform: none
}

[role=button] {
    cursor: pointer
}

select {
    word-wrap: normal
}

select:disabled {
    opacity: 1
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
    display: none !important
}

[type=button],
[type=reset],
[type=submit],
button {
    appearance: button;
    -webkit-appearance: button
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    cursor: pointer
}

::-moz-focus-inner {
    padding: 0;
    border-style: none
}

textarea {
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: calc(1.275rem + .3vw);
    line-height: inherit
}

@media (min-width:1200px) {
    legend {
        font-size: 1.5rem
    }
}

legend+* {
    clear: left
}

::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
    padding: 0
}

::-webkit-inner-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    appearance: textfield;
    -webkit-appearance: textfield
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-color-swatch-wrapper {
    padding: 0
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

::file-selector-button {
    font: inherit;
    appearance: button;
    -webkit-appearance: button
}

output {
    display: inline-block
}

iframe {
    border: 0
}

summary {
    display: list-item;
    cursor: pointer
}

progress {
    vertical-align: baseline
}

[hidden] {
    display: none !important
}

.lead {
    font-size: 1.25rem;
    font-weight: 300
}

.display-1 {
    font-size: calc(1.625rem + 4.5vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-1 {
        font-size: 5rem
    }
}

.display-2 {
    font-size: calc(1.575rem + 3.9vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-2 {
        font-size: 4.5rem
    }
}

.display-3 {
    font-size: calc(1.525rem + 3.3vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-3 {
        font-size: 4rem
    }
}

.display-4 {
    font-size: calc(1.475rem + 2.7vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-4 {
        font-size: 3.5rem
    }
}

.display-5 {
    font-size: calc(1.425rem + 2.1vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-5 {
        font-size: 3rem
    }
}

.display-6 {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-6 {
        font-size: 2.5rem
    }
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: .875em;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem
}

.blockquote>:last-child {
    margin-bottom: 0
}

.blockquote-footer {
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-size: .875em;
    color: #6c757d
}

.blockquote-footer::before {
    content: "— "
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.img-thumbnail {
    padding: .25rem;
    background-color: var(--bs-body-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    max-width: 100%;
    height: auto
}

.figure {
    display: inline-block
}

.figure-img {
    margin-bottom: .5rem;
    line-height: 1
}

.figure-caption {
    font-size: .875em;
    color: var(--bs-secondary-color)
}

.clearfix::after {
    display: block;
    clear: both;
    content: ""
}

div::part(region),div[part=region] {
    box-shadow: -1px 0px 0px 1px white inset, 1px 0px 0px 1px white inset !important;
    border-radius: 4px !important;
    height: 94% !important;
}
div::part(cursor) {
    height: 92%;
}

div::part(timeline-wrapper) {
    position: relative;
    top: 4px;
}

.wavesurferplayer {
    touch-action: none;
}

.pointer {
    cursor: pointer;
}

.text-underline {
    text-decoration: underline;
}

.no-wrap {
    white-space: nowrap;
}

.p-relative {
    position: relative;
}

@include for-mobile {
    .m-wrap {
        white-space: wrap;
    }
}

.fit-content {
    max-width: fit-content;
    margin: auto;
}

.status-badge {
    display: inline-block;
    border: var(--bs-border-width) solid var(--bs-green);
    border-radius: 24px;
    text-align: center;
    padding: 2px 16px;
    font-size: .9rem;
    color: #fff;
}

.form-card {
    max-width: 440px;
    margin: 40px auto;
    padding: 20px 40px;
    background: var(--bs-transparent-bg);
    backdrop-filter: var(--bg-blur);
    border-radius: var(--bs-border-radius-second);
    border: 1px solid var(--bs-border-color);

    .form-extra-actions {
        font-size: 14px;

        a {
            color: var(--bs-gray-400);
        }
    }
}

.register-extra-infos {
    font-size: 12px;

    p {
        text-wrap: balance;
    }
}