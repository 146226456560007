.LanguageSwitcher {
    display: flex;
    border-left: thin solid var(--bs-border-color);
    .form-select {
        border: none;
        background-color: transparent;
        padding-right: 1.75rem;
        padding-left: 1.1rem;
        font-size: 0.8rem;
        *{
            border-radius: 0px;
            font-size: 1rem;
            background-color: rgba(var(--bs-dark-rgb), 1) !important;
        }
    }
}